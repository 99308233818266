import { Component, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Import FormsModule

import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [CommonModule, FormsModule], // Add FormsModule here
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  public userService = inject(UserService);

  isEditingDisplayName = signal(false);
  editableDisplayName = this.userService.userState().displayName ?? '';

  async updateDisplayName(): Promise<void> {
    try {
      const newDisplayName = this.editableDisplayName || '';
      await this.userService.updateDisplayName(newDisplayName);
      this.isEditingDisplayName.set(false);
    } catch (error) {
      console.error('Error updating display name:', error);
    }
  }

  toggleEditDisplayName(): void {
    this.isEditingDisplayName.set(!this.isEditingDisplayName());
    this.editableDisplayName = this.userService.userState().displayName ?? '';
  }

  toggleShowInstructions(event: Event): void {
    const showInstructions = (event.target as HTMLInputElement).checked;
    this.userService
      .updatePreference('dismissInstructions', !showInstructions) // Updated method
      .then(() => {
        console.log('Show instructions preference updated successfully');
      })
      .catch((error) => {
        console.error('Error updating preference:', error);
      });
  }
}
