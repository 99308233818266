import { HintRule } from './hint-rule.interface';
import { getColorLabelById } from '../_helpers/color-utils';

export class EliminateOutsideRowOrColForSingleColorRule implements HintRule {
    getHint(boardState: string[][], board: number[][], hintStep: number): string | null {
        const N = board.length;

        // Build maps to track which colors appear in each row and column
        const rowColorMap: Record<number, Set<number>> = this.buildColorMap(boardState, board, 'row', N);
        const colColorMap: Record<number, Set<number>> = this.buildColorMap(boardState, board, 'col', N);

        // Evaluate rows for hints
        for (const row in rowColorMap) {
            const hint = this.evaluateHint(
                rowColorMap[row],
                board,
                boardState,
                +row,
                'row',
                hintStep
            );
            if (hint) return hint;
        }

        // Evaluate columns for hints
        for (const col in colColorMap) {
            const hint = this.evaluateHint(
                colColorMap[col],
                board,
                boardState,
                +col,
                'col',
                hintStep
            );
            if (hint) return hint;
        }

        return null;
    }

    private buildColorMap(
        boardState: string[][],
        board: number[][],
        type: 'row' | 'col',
        N: number
    ): Record<number, Set<number>> {
        const colorMap: Record<number, Set<number>> = {};
        for (let row = 0; row < N; row++) {
            for (let col = 0; col < N; col++) {
                const colorId = board[row][col];
                if (boardState[row][col] === 'empty') {
                    const key = type === 'row' ? row : col;
                    if (!colorMap[key]) colorMap[key] = new Set();
                    colorMap[key].add(colorId);
                }
            }
        }
        return colorMap;
    }

    private evaluateHint(
        colors: Set<number>,
        board: number[][],
        boardState: string[][],
        index: number,
        type: 'row' | 'col',
        hintStep: number
    ): string | null {
        if (colors.size !== 1) return null; // Skip if not confined to a single color
        const colorId = Array.from(colors)[0];

        // Check if the color exists in non-eliminated cells outside the row or column
        const existsOutside = board.some((rowData, rowIndex) =>
            type === 'row'
                ? rowIndex !== index &&
                rowData.some(
                    (cellColor, col) => cellColor === colorId && boardState[rowIndex][col] === 'empty'
                )
                : rowData[index] === colorId &&
                boardState[rowIndex][index] === 'empty' &&
                !colors.has(board[rowIndex][index])
        );

        if (!existsOutside) return null;

        const areaType = type === 'row' ? 'row' : 'column';
        const action = type === 'row' ? 'outside the row' : 'outside the column';

        switch (hintStep) {
            case 0:
                return `Look for ${areaType}s that only have one color option left. Eliminate cells of that color ${action}.`;
            case 1:
                return `The ${getColorLabelById(colorId)} color is confined to ${areaType} ${index + 1}. Eliminate this color ${action}.`;
        }

        return null;
    }
}
