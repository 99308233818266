// Find The Diamonds AWS Account
export const environment = {
    production: false,
    firebaseConfig: {
        apiKey: "AIzaSyC9_J8eVnHxf8hWULsL05H4CO-kGc6dy50",
        authDomain: "ftdmd-dev.firebaseapp.com",
        projectId: "ftdmd-dev",
        storageBucket: "ftdmd-dev.firebasestorage.app",
        messagingSenderId: "803651395157",
        appId: "1:803651395157:web:b2daea11ac75992e94fedc"
    },
    baseApiUrl: "https://api.dev.findthediamonds.com"
};