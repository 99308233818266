<div class="content-wrapper justify-content-center"
    *ngIf="!boardStateService.isGameStarted$() && !boardRetrievingService.isLoadingBoard$() && !boardRetrievingService.alreadyPlayed$()">

    <div class="diamonds-container text-center">
        <!-- Logo -->
        <div class="logo">
            <img src="find_the_diamonds_logo_256x256.webp" alt="Find the Diamonds" class="img-fluid">
        </div>

        <!-- Description -->
        <p class="text-muted mb-4 text-center welcome-text">
            Use your logic to find all the diamonds! Each row, column, and color zone hides exactly one diamond. But
            beware — no two diamonds can touch!
        </p>

        <!-- Buttons -->
        <div class="button-grid d-grid gap-3" *ngIf="!shareId">
            <ng-container *ngIf="userService.userState().userId; else regularButtonPlaceholder">
                <button class="btn btn-success btn-lg game-button full-width-button" (click)="startDailyBoard()"
                    *ngIf="!userService.isDailyChallengeCompleted$()"
                    [attr.data-bs-toggle]="!userService.userState().preferences.dismissInstructions ? 'modal' : null"
                    [attr.data-bs-target]="!userService.userState().preferences.dismissInstructions ? '#instructionsModal' : null">
                    Daily Challenge
                </button>
                <button class="btn btn-primary btn-lg game-button full-width-button" (click)="startGame()"
                    *ngIf="userService.isDailyChallengeCompleted$()"
                    [attr.data-bs-toggle]="!userService.userState().preferences.dismissInstructions ? 'modal' : null"
                    [attr.data-bs-target]="!userService.userState().preferences.dismissInstructions ? '#instructionsModal' : null">
                    Free Play
                </button>
                <button class="btn btn-secondary btn-lg game-button" (click)="startTutorial()"
                    [attr.data-bs-toggle]="!userService.userState().preferences.dismissInstructions ? 'modal' : null"
                    [attr.data-bs-target]="!userService.userState().preferences.dismissInstructions ? '#instructionsModal' : null">
                    Tutorial
                </button>
                <button class="btn btn-outline-dark btn-lg game-button" [routerLink]="'/login'"
                    *ngIf="!userService.userState().isLoggedIn">Log in</button>
                <button class="btn btn-outline-dark btn-lg game-button" [routerLink]="'/profile'"
                    *ngIf="userService.userState().isLoggedIn">Profile</button>
            </ng-container>
            <ng-template #regularButtonPlaceholder>
                <div class="placeholder-button full-width-button"></div>
                <div class="placeholder-button"></div>
                <div class="placeholder-button"></div>
            </ng-template>
        </div>

        <!-- Buttons -->
        <div class="button-grid d-grid gap-3" *ngIf="shareId">
            <ng-container *ngIf="userService.userState().userId; else sharedBoardButtonPlaceholder">
                <button class="btn btn-primary btn-lg game-button" (click)="startGame()"
                    [attr.data-bs-toggle]="!userService.userState().preferences.dismissInstructions ? 'modal' : null"
                    [attr.data-bs-target]="!userService.userState().preferences.dismissInstructions ? '#instructionsModal' : null">
                    Play Shared Board
                </button>
                <button class="btn btn-outline-dark btn-lg game-button" [routerLink]="'/login'"
                    *ngIf="!userService.userState().isLoggedIn">Log in</button>
                <button class="btn btn-outline-dark btn-lg game-button" [routerLink]="'/profile'"
                    *ngIf="userService.userState().isLoggedIn">Profile</button>
            </ng-container>
            <ng-template #sharedBoardButtonPlaceholder>
                <div class="placeholder-button"></div>
                <div class="placeholder-button"></div>
            </ng-template>
        </div>

        <!-- Daily Challenge Stats Block -->
        <div class="daily-challenge-results mt-3 p-2 border rounded" *ngIf="!shareId">
            <!-- Stats Table -->
            <table class="table table-borderless mb-0 text-center">
                <thead>
                    <tr>
                        <th scope="col" colspan="4">
                            <div class="d-flex justify-content-between align-items-center">
                                <span>&nbsp;</span>
                                <span>Daily Challenge Stats</span>
                                <i class="bi bi-info-circle text-muted" data-bs-toggle="modal"
                                    data-bs-target="#statsInfoModal" role="button"></i>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th scope="col">Time</th>
                        <th scope="col">Moves</th>
                        <th scope="col">Clears</th>
                        <th scope="col">Beat Bot</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {{ dailyChallengeData?.completionTime
                            ? (dailyChallengeData.completionTime | date: 'mm:ss')
                            : '--:--' }}
                            <span
                                [innerHTML]="getUserPerformanceEmoji(dailyChallengeData?.stats?.userPercentile)"></span>
                        </td>
                        <td>{{ dailyChallengeData?.clicks?.length || '--' }}</td>
                        <td>{{ dailyChallengeData?.boardClears || '--' }}</td>
                        <td>
                            <span *ngIf="dailyChallengeData?.additionalStats?.beatComputer === true">✅</span>
                            <span *ngIf="dailyChallengeData?.additionalStats?.beatComputer === false">❌</span>
                            <span *ngIf="dailyChallengeData?.additionalStats?.beatComputer != true && 
                                dailyChallengeData?.additionalStats?.beatComputer != false">--</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- Buttons Section -->
            <div class="d-flex justify-content-around mt-3" *ngIf="userService.isDailyChallengeCompleted$()">
                <!-- Share Button -->
                <button class="btn btn-primary" (click)="shareDailyBoard()">Share Result</button>

                <!-- View Board Button -->
                <button class="btn btn-success" (click)="viewDailyBoard()">View Board</button>
            </div>
        </div>

        <!-- Terms and Privacy Links -->
        <p class="text-center mt-4 text-muted">
            <a [routerLink]="'/terms'" class="text-muted text-decoration-none">Terms of Service</a>
            <span class="mx-2">|</span>
            <a [routerLink]="'/privacy'" class="text-muted text-decoration-none">Privacy Policy</a>
        </p>
    </div>

</div>

<div class="board-container text-center">
    <!-- Game Board -->
    <app-board></app-board>
</div>

<div class="timer-container text-center">
    <!-- Timer and Messages -->
    <app-timer></app-timer>
</div>

<!-- Instructions Modal -->
<div class="modal fade" id="instructionsModal" tabindex="-1" aria-labelledby="instructionsModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <h5 class="text-center mb-2">Rules</h5>
                <ul class="game-rules">
                    <li>There is exactly one diamond in each:</li>
                    <ul>
                        <li>row</li>
                        <li>column</li>
                        <li>color zone</li>
                    </ul>
                    <li>Diamonds cannot touch other diamonds horizontally, vertically, or diagonally.</li>
                </ul>

                <h5 class="mt-4 mb-2 text-center">How to Play</h5>
                <div class="game-mechanics">
                    <div class="mechanic-item">
                        <span class="icon">✖</span>
                        <span>If you think a cell cannot contain a diamond, click it once to mark it as
                            eliminated.</span>
                    </div>
                    <div class="mechanic-item">
                        <span class="icon">💎</span>
                        <span>If you think a cell contains a diamond, click it a second time to mark it with a
                            diamond.</span>
                    </div>
                    <div class="mechanic-item">
                        <span class="icon">↩️</span>
                        <span>If you've made a mistake, click the cell a third time to clear its marking.</span>
                    </div>
                    <div class="mechanic-item">
                        <span class="icon">❌</span>
                        <span>If the game detects a rule violation, problem cells will show a red.</span>
                    </div>
                    <div class="mechanic-item">
                        <span class="icon">🏆</span>
                        <span>The game will finish automatically when you've successfully mined all the
                            diamonds!</span>
                    </div>
                </div>
                <div class="form-check mt-3">
                    <input type="checkbox" class="form-check-input" id="dismissInstructions"
                        [(ngModel)]="userService.userState().preferences.dismissInstructions"
                        (change)="onDismissInstructionsChange($event)" />
                    <label class="form-check-label" for="dismissInstructions">Do not show this again</label>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="closeModal()">
                    Got it!
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Help Modal -->
<div class="modal fade" id="statsInfoModal" tabindex="-1" aria-labelledby="statsInfoModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="statsInfoModalLabel">Daily Challenge Stats Key</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-start">
                <p><strong>Time:</strong> The time it took you to complete the board.</p>
                <p>
                    <strong>Moves:</strong> The total number of moves you made, including marking cells as eliminated,
                    clearing diamonds, clearing boards, or requesting hints. Placing a diamond does not count as an
                    additional move.
                </p>
                <p><strong>Clears:</strong> The number of times you cleared the board.</p>
                <p>
                    <strong>Beat Bot:</strong>
                    Indicates whether you used less moves than the bot did to complete the daily challenge.
                </p>
                <p>
                    <strong>🔥/❄️ Emojis:</strong> These indicate your percentile ranking compared to everyone who
                    completed the daily challenge:
                </p>
                <ul>
                    <li><strong>🔥🔥:</strong> Top 20%</li>
                    <li><strong>🔥:</strong> 20-40%</li>
                    <li>No emoji: 40-60%</li>
                    <li><strong>❄️:</strong> 60-80%</li>
                    <li><strong>❄️❄️:</strong> Bottom 20%</li>
                </ul>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Got it!</button>
            </div>
        </div>
    </div>
</div>

<!-- Toast element -->
<app-toast *ngIf="showToast" [message]="'Board link copied to clipboard!'"></app-toast>