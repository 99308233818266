import { HintRule } from './hint-rule.interface';
import { getColorLabelById } from '../_helpers/color-utils';

export class FourColorsInFourRowsOrColsRule implements HintRule {
    getHint(boardState: string[][], board: number[][], hintStep: number): string | null {
        const N = board.length;
        const colorRowMap: Record<number, Set<number>> = {};
        const colorColMap: Record<number, Set<number>> = {};

        // Build maps to track which rows and columns each color appears in
        for (let row = 0; row < N; row++) {
            for (let col = 0; col < N; col++) {
                const colorId = board[row][col];
                if (boardState[row][col] === 'empty') {
                    if (!colorRowMap[colorId]) {
                        colorRowMap[colorId] = new Set();
                    }
                    if (!colorColMap[colorId]) {
                        colorColMap[colorId] = new Set();
                    }
                    colorRowMap[colorId].add(row);
                    colorColMap[colorId].add(col);
                }
            }
        }

        // Handle rows
        for (const colorId1 in colorRowMap) {
            const rowSet1 = colorRowMap[colorId1];
            if (rowSet1.size === 4) {
                const [row1, row2, row3, row4] = Array.from(rowSet1);

                for (const colorId2 in colorRowMap) {
                    if (colorId1 !== colorId2) {
                        const rowSet2 = colorRowMap[colorId2];
                        if ([...rowSet2].every((row) => [row1, row2, row3, row4].includes(row))) {
                            for (const colorId3 in colorRowMap) {
                                if (colorId1 !== colorId3 && colorId2 !== colorId3) {
                                    const rowSet3 = colorRowMap[colorId3];
                                    if ([...rowSet3].every((row) => [row1, row2, row3, row4].includes(row))) {
                                        for (const colorId4 in colorRowMap) {
                                            if (
                                                colorId1 !== colorId4 &&
                                                colorId2 !== colorId4 &&
                                                colorId3 !== colorId4
                                            ) {
                                                const rowSet4 = colorRowMap[colorId4];
                                                if ([...rowSet4].every((row) => [row1, row2, row3, row4].includes(row))) {
                                                    // Found four colors confined to the same four rows

                                                    const actionableCellsRow1 = board[row1].map((cell, col) => ({
                                                        col,
                                                        color: cell,
                                                        isEmpty: boardState[row1][col] === 'empty',
                                                        isDifferent:
                                                            cell !== parseInt(colorId1) &&
                                                            cell !== parseInt(colorId2) &&
                                                            cell !== parseInt(colorId3) &&
                                                            cell !== parseInt(colorId4),
                                                    }));
                                                    const actionableCellsRow2 = board[row2].map((cell, col) => ({
                                                        col,
                                                        color: cell,
                                                        isEmpty: boardState[row2][col] === 'empty',
                                                        isDifferent:
                                                            cell !== parseInt(colorId1) &&
                                                            cell !== parseInt(colorId2) &&
                                                            cell !== parseInt(colorId3) &&
                                                            cell !== parseInt(colorId4),
                                                    }));
                                                    const actionableCellsRow3 = board[row3].map((cell, col) => ({
                                                        col,
                                                        color: cell,
                                                        isEmpty: boardState[row3][col] === 'empty',
                                                        isDifferent:
                                                            cell !== parseInt(colorId1) &&
                                                            cell !== parseInt(colorId2) &&
                                                            cell !== parseInt(colorId3) &&
                                                            cell !== parseInt(colorId4),
                                                    }));
                                                    const actionableCellsRow4 = board[row4].map((cell, col) => ({
                                                        col,
                                                        color: cell,
                                                        isEmpty: boardState[row4][col] === 'empty',
                                                        isDifferent:
                                                            cell !== parseInt(colorId1) &&
                                                            cell !== parseInt(colorId2) &&
                                                            cell !== parseInt(colorId3) &&
                                                            cell !== parseInt(colorId4),
                                                    }));

                                                    const hasNonConformingCellsRow1 = actionableCellsRow1.some(
                                                        (cell) => cell.isEmpty && cell.isDifferent
                                                    );
                                                    const hasNonConformingCellsRow2 = actionableCellsRow2.some(
                                                        (cell) => cell.isEmpty && cell.isDifferent
                                                    );
                                                    const hasNonConformingCellsRow3 = actionableCellsRow3.some(
                                                        (cell) => cell.isEmpty && cell.isDifferent
                                                    );
                                                    const hasNonConformingCellsRow4 = actionableCellsRow4.some(
                                                        (cell) => cell.isEmpty && cell.isDifferent
                                                    );

                                                    if (
                                                        !hasNonConformingCellsRow1 &&
                                                        !hasNonConformingCellsRow2 &&
                                                        !hasNonConformingCellsRow3 &&
                                                        !hasNonConformingCellsRow4
                                                    ) {
                                                        continue; // Skip these rows if no actionable cells remain
                                                    }

                                                    switch (hintStep) {
                                                        case 0:
                                                            return `If four colors are confined to the same four rows, eliminate other cells in those rows.`;
                                                        case 1:
                                                            return `The ${getColorLabelById(
                                                                Number(colorId1)
                                                            )}, ${getColorLabelById(
                                                                Number(colorId2)
                                                            )}, ${getColorLabelById(
                                                                Number(colorId3)
                                                            )}, and ${getColorLabelById(
                                                                Number(colorId4)
                                                            )} colors are confined to rows ${row1 + 1
                                                                }, ${row2 + 1}, ${row3 + 1}, and ${row4 + 1}. Eliminate all other cells in those rows.`;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // Handle columns
        for (const colorId1 in colorColMap) {
            const colSet1 = colorColMap[colorId1];
            if (colSet1.size === 4) {
                const [col1, col2, col3, col4] = Array.from(colSet1);

                for (const colorId2 in colorColMap) {
                    if (colorId1 !== colorId2) {
                        const colSet2 = colorColMap[colorId2];
                        if ([...colSet2].every((col) => [col1, col2, col3, col4].includes(col))) {
                            for (const colorId3 in colorColMap) {
                                if (colorId1 !== colorId3 && colorId2 !== colorId3) {
                                    const colSet3 = colorColMap[colorId3];
                                    if ([...colSet3].every((col) => [col1, col2, col3, col4].includes(col))) {
                                        for (const colorId4 in colorColMap) {
                                            if (
                                                colorId1 !== colorId4 &&
                                                colorId2 !== colorId4 &&
                                                colorId3 !== colorId4
                                            ) {
                                                const colSet4 = colorColMap[colorId4];
                                                if (
                                                    [...colSet4].every((col) =>
                                                        [col1, col2, col3, col4].includes(col)
                                                    )
                                                ) {
                                                    // Found four colors confined to the same four columns

                                                    switch (hintStep) {
                                                        case 0:
                                                            return `If four colors are confined to the same four columns, eliminate other cells in those columns.`;
                                                        case 1:
                                                            return `The ${getColorLabelById(
                                                                Number(colorId1)
                                                            )}, ${getColorLabelById(
                                                                Number(colorId2)
                                                            )}, ${getColorLabelById(
                                                                Number(colorId3)
                                                            )}, and ${getColorLabelById(
                                                                Number(colorId4)
                                                            )} colors are confined to columns ${col1 + 1
                                                                }, ${col2 + 1}, ${col3 + 1}, and ${col4 + 1}. Eliminate all other cells in those columns.`;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return null; // No hints available
    }
}
