import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { FirebaseError } from 'firebase/app';

import { LoginService } from '../../../services/login.service';

@Component({
  selector: 'app-login-email',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RouterModule],
  templateUrl: './login-email.component.html',
  styleUrl: './login-email.component.scss'
})
export class LoginEmailComponent {
  emailForm: FormGroup;
  errorMessage: string = '';

  constructor(
    private readonly fb: FormBuilder,
    private readonly loginService: LoginService,
    private readonly router: Router
  ) {
    this.emailForm = this.fb.group({
      email: [this.loginService.getEmail() ?? '', [Validators.required, Validators.email]],
    });
  }

  // Check if error should be shown
  showError(controlName: string): boolean {
    const control = this.emailForm.get(controlName);
    return !!(control && control.invalid && control.touched);
  }

  // Clear the error when typing
  clearError(controlName: string): void {
    const control = this.emailForm.get(controlName);
    if (control?.touched) {
      control.markAsUntouched();
    }
  }

  // Set touched when leaving the field
  onBlur(controlName: string): void {
    const control = this.emailForm.get(controlName);
    if (control && !control.touched) {
      control.markAsTouched();
    }
  }

  onContinue(): void {
    if (this.emailForm.valid) {
      const email = this.emailForm.value.email;
      this.loginService.setEmail(email);
      this.router.navigate(['/login/password']);
    } else {
      const control = this.emailForm.get('email');
      if (control) {
        control.markAsTouched(); // Mark the field as touched to show validation errors
      }
    }
  }

  async continueWithGoogle(): Promise<void> {
    try {
      await this.loginService.continueWithGoogle();
    } catch (error) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case 'auth/popup-closed-by-user':
            this.errorMessage = 'Sign-in popup was closed before completing. Please try again.';
            break;
          case 'auth/account-exists-with-different-credential':
            this.errorMessage =
              'This email is already associated with a different login method. Try logging in with that method.';
            break;
          case 'auth/network-request-failed':
            this.errorMessage = 'Network error. Please check your connection.';
            break;
          case 'auth/too-many-requests':
            this.errorMessage = 'Too many failed attempts. Please try again later.';
            break;
          case 'auth/internal-error':
            this.errorMessage = 'An internal error occurred. Please try again later.';
            break;
          default:
            this.errorMessage = 'An unexpected error occurred. Please try again.';
        }
      } else {
        console.error('Unexpected error:', error);
        this.errorMessage = 'An unknown error occurred. Please try again.';
      }
    }
  }
}