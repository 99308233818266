import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '../services/user.service';

export const authRedirectGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService);
  const router = inject(Router);

  // Check if the user is logged in
  if (userService.isUserLoggedIn$()) {
    router.navigate(['/']); // Redirect to the homepage
    return false; // Block navigation
  }

  return true; // Allow navigation
};
