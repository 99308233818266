import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { UserService } from '../../services/user.service';
import { BoardRetrievingService } from '../../services/board-retrieving.service';
import { BoardStateService } from '../../services/board-state.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  profileImageUrl: string = 'https://cdn.jsdelivr.net/npm/bootstrap-icons/icons/person-circle.svg'; // Default user icon

  constructor(
    public readonly userService: UserService,
    public readonly boardRetrievingService: BoardRetrievingService,
    public readonly boardStateService: BoardStateService
  ) { }

  logout(): void {
    this.clearBoardState();
    this.userService.signOut();
  }

  pauseTimer(): void {
    if (this.boardStateService.isGameStarted$() && !this.boardStateService.gameCompleted$()) {
      this.boardStateService.pauseTimer();
    }
  }

  resumeTimer(): void {
    this.boardStateService.resumeTimer();
  }

  toggleAutoEliminateCells(event: Event): void {
    const checked = (event.target as HTMLInputElement).checked;
    this.boardStateService.toggleAutoEliminateCells(checked);
  }

  onShowInstructionsChange(event: Event): void {
    const showInstructions = (event.target as HTMLInputElement).checked;
    const dismissInstructions = !showInstructions; // Inverse logic for the preference

    this.userService
      .updatePreference('dismissInstructions', dismissInstructions) // Updated method
      .then(() => {
        console.log('Preference updated successfully:', { dismissInstructions });
      })
      .catch((error) => {
        console.error('Error saving preference:', error);
      });
  }

  goToSplashScreen(): void {
    console.log('Clearing board state and navigating to splash screen');
    this.clearBoardState();
  }

  clearBoardState(): void {
    this.boardStateService.resetBoardState();
    this.boardRetrievingService.resetRetrievingStates();
  }

}
