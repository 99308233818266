import { HintRule } from './hint-rule.interface';
import { getColorLabelById } from '../_helpers/color-utils';

export class ThreeColorsInThreeRowsOrColsRule implements HintRule {
    getHint(boardState: string[][], board: number[][], hintStep: number): string | null {
        const N = board.length;
        const colorRowMap: Record<number, Set<number>> = {};
        const colorColMap: Record<number, Set<number>> = {};

        // Build maps to track which rows and columns each color appears in
        for (let row = 0; row < N; row++) {
            for (let col = 0; col < N; col++) {
                const colorId = board[row][col];
                if (boardState[row][col] === 'empty') {
                    if (!colorRowMap[colorId]) {
                        colorRowMap[colorId] = new Set();
                    }
                    if (!colorColMap[colorId]) {
                        colorColMap[colorId] = new Set();
                    }
                    colorRowMap[colorId].add(row);
                    colorColMap[colorId].add(col);
                }
            }
        }

        // Handle rows
        for (const colorId1 in colorRowMap) {
            const rowSet1 = colorRowMap[colorId1];
            if (rowSet1.size === 3) {
                const [row1, row2, row3] = Array.from(rowSet1);

                for (const colorId2 in colorRowMap) {
                    if (colorId1 !== colorId2) {
                        const rowSet2 = colorRowMap[colorId2];
                        if ([...rowSet2].every((row) => [row1, row2, row3].includes(row))) {
                            for (const colorId3 in colorRowMap) {
                                if (colorId1 !== colorId3 && colorId2 !== colorId3) {
                                    const rowSet3 = colorRowMap[colorId3];
                                    if ([...rowSet3].every((row) => [row1, row2, row3].includes(row))) {
                                        // Found three colors confined to the same three rows

                                        // Check for actionable cells
                                        const actionableCellsRow1 = board[row1].map((cell, col) => ({
                                            col,
                                            color: cell,
                                            isEmpty: boardState[row1][col] === 'empty',
                                            isDifferent:
                                                cell !== parseInt(colorId1) &&
                                                cell !== parseInt(colorId2) &&
                                                cell !== parseInt(colorId3),
                                        }));
                                        const actionableCellsRow2 = board[row2].map((cell, col) => ({
                                            col,
                                            color: cell,
                                            isEmpty: boardState[row2][col] === 'empty',
                                            isDifferent:
                                                cell !== parseInt(colorId1) &&
                                                cell !== parseInt(colorId2) &&
                                                cell !== parseInt(colorId3),
                                        }));
                                        const actionableCellsRow3 = board[row3].map((cell, col) => ({
                                            col,
                                            color: cell,
                                            isEmpty: boardState[row3][col] === 'empty',
                                            isDifferent:
                                                cell !== parseInt(colorId1) &&
                                                cell !== parseInt(colorId2) &&
                                                cell !== parseInt(colorId3),
                                        }));

                                        const hasNonConformingCellsRow1 = actionableCellsRow1.some(
                                            (cell) => cell.isEmpty && cell.isDifferent
                                        );
                                        const hasNonConformingCellsRow2 = actionableCellsRow2.some(
                                            (cell) => cell.isEmpty && cell.isDifferent
                                        );
                                        const hasNonConformingCellsRow3 = actionableCellsRow3.some(
                                            (cell) => cell.isEmpty && cell.isDifferent
                                        );

                                        if (
                                            !hasNonConformingCellsRow1 &&
                                            !hasNonConformingCellsRow2 &&
                                            !hasNonConformingCellsRow3
                                        ) {
                                            continue; // Skip these rows if no actionable cells remain
                                        }

                                        switch (hintStep) {
                                            case 0:
                                                return `If three colors are confined to the same three rows, eliminate other cells in those rows.`;
                                            case 1:
                                                return `The ${getColorLabelById(
                                                    Number(colorId1)
                                                )}, ${getColorLabelById(Number(colorId2))}, and ${getColorLabelById(
                                                    Number(colorId3)
                                                )} colors are confined to rows ${row1 + 1
                                                    }, ${row2 + 1}, and ${row3 + 1}. Eliminate all other cells in those rows.`;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // Handle columns
        for (const colorId1 in colorColMap) {
            const colSet1 = colorColMap[colorId1];
            if (colSet1.size === 3) {
                const [col1, col2, col3] = Array.from(colSet1);

                for (const colorId2 in colorColMap) {
                    if (colorId1 !== colorId2) {
                        const colSet2 = colorColMap[colorId2];
                        if ([...colSet2].every((col) => [col1, col2, col3].includes(col))) {
                            for (const colorId3 in colorColMap) {
                                if (colorId1 !== colorId3 && colorId2 !== colorId3) {
                                    const colSet3 = colorColMap[colorId3];
                                    if ([...colSet3].every((col) => [col1, col2, col3].includes(col))) {
                                        // Found three colors confined to the same three columns

                                        // Check for actionable cells
                                        const actionableCellsCol1 = board.map((row, rowIndex) => ({
                                            row: rowIndex,
                                            color: row[col1],
                                            isEmpty: boardState[rowIndex][col1] === 'empty',
                                            isDifferent:
                                                row[col1] !== parseInt(colorId1) &&
                                                row[col1] !== parseInt(colorId2) &&
                                                row[col1] !== parseInt(colorId3),
                                        }));
                                        const actionableCellsCol2 = board.map((row, rowIndex) => ({
                                            row: rowIndex,
                                            color: row[col2],
                                            isEmpty: boardState[rowIndex][col2] === 'empty',
                                            isDifferent:
                                                row[col2] !== parseInt(colorId1) &&
                                                row[col2] !== parseInt(colorId2) &&
                                                row[col2] !== parseInt(colorId3),
                                        }));
                                        const actionableCellsCol3 = board.map((row, rowIndex) => ({
                                            row: rowIndex,
                                            color: row[col3],
                                            isEmpty: boardState[rowIndex][col3] === 'empty',
                                            isDifferent:
                                                row[col3] !== parseInt(colorId1) &&
                                                row[col3] !== parseInt(colorId2) &&
                                                row[col3] !== parseInt(colorId3),
                                        }));

                                        const hasNonConformingCellsCol1 = actionableCellsCol1.some(
                                            (cell) => cell.isEmpty && cell.isDifferent
                                        );
                                        const hasNonConformingCellsCol2 = actionableCellsCol2.some(
                                            (cell) => cell.isEmpty && cell.isDifferent
                                        );
                                        const hasNonConformingCellsCol3 = actionableCellsCol3.some(
                                            (cell) => cell.isEmpty && cell.isDifferent
                                        );

                                        if (
                                            !hasNonConformingCellsCol1 &&
                                            !hasNonConformingCellsCol2 &&
                                            !hasNonConformingCellsCol3
                                        ) {
                                            continue; // Skip these columns if no actionable cells remain
                                        }

                                        switch (hintStep) {
                                            case 0:
                                                return `If three colors are confined to the same three columns, eliminate other cells in those columns.`;
                                            case 1:
                                                return `The ${getColorLabelById(
                                                    Number(colorId1)
                                                )}, ${getColorLabelById(Number(colorId2))}, and ${getColorLabelById(
                                                    Number(colorId3)
                                                )} colors are confined to columns ${col1 + 1
                                                    }, ${col2 + 1}, and ${col3 + 1}. Eliminate all other cells in those columns.`;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return null; // No hints available
    }
}
