import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Stats } from '../_models/stats.model';
import { UserService } from './user.service';
import { LocalStorageService } from './local-storage.service';

const defaultStats: Stats = {
  averageBoardClears: 0,
  averageClicks: 0,
  averageCompletionTime: 0,
  averageCompletionString: '',
  fastestCompletionTime: 0,
  medianClicks: 0,
  medianCompletionTime: 0,
  medianCompletionString: '',
  totalCompletions: 0,
  userPercentile: 0
};

const defaultAdditionalStats = {
  beatComputer: false,
  diamondsMinedMilestone: 0,
  milestoneBoard: false,
  personalBest: false,
  totalBoardsPlayed: 0,
  totalDiamondsMined: 0,
  hintUsed: false
};

@Injectable({
  providedIn: 'root'
})
export class BoardSavingService {

  private isSaving = signal<boolean>(false);
  private readonly baseApiUrl = environment.baseApiUrl;
  private statsAvailable = signal(false);
  private stats = signal<Stats>(defaultStats);
  private userRating = signal<number>(0);

  readonly isSaving$ = this.isSaving.asReadonly();
  readonly statsAvailable$ = this.statsAvailable.asReadonly();
  readonly stats$ = this.stats.asReadonly();
  readonly userRating$ = this.userRating.asReadonly();

  constructor(
    private readonly http: HttpClient,
    private readonly userService: UserService,
    private readonly localStorageService: LocalStorageService
  ) { }

  postCompletionData(
    boardId: string,
    completionTime: number,
    clicks: { action: string, cell: string, solved_at: Date }[],
    boardClears: number
  ) {
    this.isSaving.set(true); // Set saving to true when request starts
    this.userRating.set(0); // Reset the user rating
    const url = `${this.baseApiUrl}/results`;
    const payload = {
      boardId,
      completionTime,
      clicks,
      boardClears
    };

    this.statsAvailable.set(false); // Reset the stats availability

    return this.http.post<any>(url, payload).subscribe({
      next: (response) => {
        if (response?.stats) {
          const formattedCompletionTime = this.formatCompletionTime(response.stats.averageCompletionTime);
          const formattedMedianCompletionTime = this.formatCompletionTime(response.stats.medianCompletionTime);

          const processedStats: Stats = {
            averageBoardClears: response.stats.averageBoardClears,
            averageClicks: response.stats.averageClicks,
            averageCompletionTime: response.stats.averageCompletionTime,
            averageCompletionString: formattedCompletionTime,
            fastestCompletionTime: response.stats.fastestCompletionTime,
            medianClicks: response.stats.medianClicks,
            medianCompletionTime: response.stats.medianCompletionTime,
            medianCompletionString: formattedMedianCompletionTime,
            totalCompletions: response.stats.totalCompletions,
            userPercentile: response.stats.userPercentile
          };

          this.stats.set(processedStats);
          this.statsAvailable.set(true);
        }

        const dailyChallengeData = this.localStorageService.getDailyChallengeData(this.userService.userId$()) || {};
        const isDailyChallenge = dailyChallengeData?.boardId === boardId;

        if (isDailyChallenge) {
          this.localStorageService.updateLocalStorage(this.userService.userId$(), {
            dailyChallenge: {
              ...dailyChallengeData,
              completed: true,
              stats: {
                averageBoardClears: response.stats.averageBoardClears,
                averageClicks: response.stats.averageClicks,
                averageCompletionTime: response.stats.averageCompletionTime,
                fastestCompletionTime: response.stats.fastestCompletionTime,
                medianClicks: response.stats.medianClicks,
                medianCompletionTime: response.stats.medianCompletionTime,
                totalCompletions: response.stats.totalCompletions,
                userPercentile: response.stats.userPercentile,
              },
              additionalStats: {
                personalBest: response.additionalStats.personalBest,
                milestoneBoard: response.additionalStats.milestoneBoard,
                diamondsMinedMilestone: response.additionalStats.diamondsMinedMilestone,
                beatComputer: response.additionalStats.beatComputer,
                totalBoardsPlayed: response.additionalStats.totalBoardsPlayed,
                totalDiamondsMined: response.additionalStats.totalDiamondsMined,
                hintUsed: response.additionalStats.hintUsed,
              },
              completionTime,
              clicks,
              boardClears,
            },
          });

          // Update the UserService signal for daily challenge completion
          this.userService.setDailyChallengeCompleted(true);
        }

        this.isSaving.set(false); // Set saving to false when request completes
      },
      error: (error) => {
        console.error('Error posting completion data:', error);
        this.isSaving.set(false); // Set saving to false when request fails
      }
    });
  }

  formatCompletionTime(timeInMs: number): string {
    const totalSeconds = Math.floor(timeInMs / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  }

  rateBoard(boardId: string, rating: number): void {
    this.userRating.set(rating);
    this.updateRating(boardId, rating);
  }

  updateRating(boardId: string, rating: number) {
    const url = `${this.baseApiUrl}/results`;
    let payload = {
      boardId,
      rating
    };

    return this.http.post(url, payload).subscribe({
      next: (response) => {
      },
      error: (error) => {
        console.error('Error updating rating:', error);
      }
    });
  }

  setRating(rating: number): void {
    this.userRating.set(rating);
  }

  loadStats(boardStats: any): void {
    if (boardStats) {
      const formattedCompletionTime = this.formatCompletionTime(boardStats.averageCompletionTime);
      const formattedMedianCompletionTime = this.formatCompletionTime(boardStats.medianCompletionTime);

      const processedStats: Stats = {
        averageBoardClears: boardStats.averageBoardClears,
        averageClicks: boardStats.averageClicks,
        averageCompletionTime: boardStats.averageCompletionTime,
        averageCompletionString: formattedCompletionTime,
        fastestCompletionTime: boardStats.fastestCompletionTime,
        medianClicks: boardStats.medianClicks,
        medianCompletionTime: boardStats.medianCompletionTime,
        medianCompletionString: formattedMedianCompletionTime,
        totalCompletions: boardStats.totalCompletions,
        userPercentile: boardStats.userPercentile
      };

      this.stats.set(processedStats);
      this.statsAvailable.set(true);
    }
  }

}
