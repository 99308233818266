import { HintRule } from './hint-rule.interface';
import { getColorLabelById } from '../_helpers/color-utils';

export class SingleRowOrColRule implements HintRule {
    getHint(boardState: string[][], board: number[][], hintStep: number): string | null {
        const colorRowMap: Record<number, Set<number>> = {};
        const colorColMap: Record<number, Set<number>> = {};

        const N = board.length;

        // Build maps to track in which rows and columns each color appears
        for (let row = 0; row < boardState.length; row++) {
            for (let col = 0; col < boardState[row].length; col++) {
                const colorId = board[row][col];
                if (boardState[row][col] === 'empty') {
                    if (!colorRowMap[colorId]) {
                        colorRowMap[colorId] = new Set();
                    }
                    if (!colorColMap[colorId]) {
                        colorColMap[colorId] = new Set();
                    }
                    colorRowMap[colorId].add(row);
                    colorColMap[colorId].add(col);
                }
            }
        }

        // Check for colors confined to a single row
        for (const colorIdStr in colorRowMap) {
            const colorId = parseInt(colorIdStr, 10);
            if (colorRowMap[colorId].size === 1) {
                const confinedRow = Array.from(colorRowMap[colorId])[0];

                // Check if there are any non-conforming, non-eliminated cells in the row
                const actionableCells = board[confinedRow].map((cell, col) => ({
                    col,
                    color: cell,
                    isEmpty: boardState[confinedRow][col] === 'empty',
                    isDifferent: cell !== colorId
                }));

                const hasNonConformingCells = actionableCells.some(
                    (cell) => cell.isEmpty && cell.isDifferent
                );

                if (!hasNonConformingCells) {
                    continue; // Skip this row if no actionable cells remain
                }

                switch (hintStep) {
                    case 0:
                        return 'Look for any colors that only exist in a single row.';
                    case 1:
                        return `You can eliminate non-${getColorLabelById(colorId)} cells in row ${confinedRow + 1}.`;
                }
            }
        }

        // Check for colors confined to a single column
        for (const colorIdStr in colorColMap) {
            const colorId = parseInt(colorIdStr, 10);
            if (colorColMap[colorId].size === 1) {
                const confinedCol = Array.from(colorColMap[colorId])[0];

                // Check if there are any non-conforming, non-eliminated cells in the column
                const actionableCells = board.map((row, rowIndex) => ({
                    row: rowIndex,
                    color: row[confinedCol],
                    isEmpty: boardState[rowIndex][confinedCol] === 'empty',
                    isDifferent: row[confinedCol] !== colorId
                }));

                const hasNonConformingCells = actionableCells.some(
                    (cell) => cell.isEmpty && cell.isDifferent
                );

                if (!hasNonConformingCells) {
                    continue; // Skip this column if no actionable cells remain
                }

                switch (hintStep) {
                    case 0:
                        return 'Look for any colors that only exist in a single column.';
                    case 1:
                        return `You can eliminate non-${getColorLabelById(colorId)} cells in column ${confinedCol + 1}.`;
                }
            }
        }

        return null;
    }
}