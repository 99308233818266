<!-- Timer or Congratulations Message -->
<div *ngIf="!boardRetrievingService.isLoadingBoard$() && (boardStateService.isGameStarted$() || boardRetrievingService.alreadyPlayed$())"
    class="timer">
    <div class="timer-container">
        <span *ngIf="!boardRetrievingService.isTutorialActive$() && !boardStateService.gameCompleted$()"
            class="timer">Time Elapsed: {{ formattedTime }}</span>
        <span *ngIf="boardStateService.gameCompleted$() && !boardRetrievingService.alreadyPlayed$()"
            class="congratulations-title">🎉
            Congratulations! 🎉</span>
        <span *ngIf="boardRetrievingService.isTutorialActive$() && boardStateService.gameCompleted$()"
            class="congratulations-message">
            You found all the diamonds!
        </span>
        <span
            *ngIf="!boardRetrievingService.isTutorialActive$() && boardStateService.gameCompleted$() && !boardRetrievingService.alreadyPlayed$()"
            class="congratulations-message">
            {{ formattedTimeMessage() }}
        </span>
        <span *ngIf="boardStateService.gameCompleted$() && boardRetrievingService.alreadyPlayed$()"
            class="congratulations-title">
            You beat this board already!
        </span>
        <span
            *ngIf="!boardRetrievingService.isTutorialActive$() && boardStateService.gameCompleted$() && boardRetrievingService.alreadyPlayed$()"
            class="congratulations-message">
            {{ formattedTimeMessage() }}
        </span>
    </div>

    <!-- Clear Current Board Button -->
    <div class="clear-board-container">
        <button *ngIf="!boardStateService.gameCompleted$()" class="btn btn-success mt-3" (click)="showHint()"
            [disabled]="hintService.hintCooldownActive$() || hintService.hintStep$() > 1">
            Get Hint
        </button>
        <button
            *ngIf="boardStateService.isGameStarted$() && !boardStateService.gameCompleted$() && !boardRetrievingService.isLoadingBoard$()"
            class="btn btn-danger mt-3" (click)="boardStateService.clearBoard()">
            Clear Current Board
        </button>
    </div>

    <!-- Hint Text -->
    <div class="hint-container mt-3">
        <!-- Hint Text -->
        <p class="hint-text text-center mt-2" *ngIf="hintService.hintVisible$()">{{ hintService.hintText$() }}</p>
        <!-- Progress Bar in a separate container -->
        <div class="progress-container">
            <div class="progress my-2" *ngIf="hintService.hintCooldownActive$()">
                <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar"
                    [style.width]="hintService.hintCooldownProgress$() + '%'"
                    [attr.aria-valuenow]="hintService.hintCooldownProgress$()" aria-valuemin="0" aria-valuemax="100">
                    {{ hintService.hintCooldownTimeLeft$() === 1 ? '1s' : 'Next hint available in ' +
                    hintService.hintCooldownTimeLeft$() + 's' }}
                </div>
            </div>
        </div>
    </div>


    <!-- Share, Stats, and Next Board Buttons for non-Tutorial boards-->
    <div class="clear-board-container d-flex justify-content-center"
        *ngIf="!boardRetrievingService.isTutorialActive$() && !boardSavingService.isSaving$()">
        <button class="btn btn-primary mt-3 mx-2 flex-grow-1" *ngIf="boardStateService.gameCompleted$()"
            (click)="shareBoard()">
            Share
        </button>
        <button class="btn btn-secondary mt-3 mx-2 flex-grow-1"
            *ngIf="!boardRetrievingService.isLoadingBoard$() && boardStateService.gameCompleted$()"
            [disabled]="!boardSavingService.statsAvailable$()" data-bs-toggle="modal" data-bs-target="#statsModal"
            (click)="statsButton()">
            Stats
        </button>
        <button class="btn btn-primary mt-3 mx-2 flex-grow-1"
            *ngIf="!boardRetrievingService.isLoadingBoard$() && boardStateService.gameCompleted$() && boardRetrievingService.isPlayingDailyBoard$()"
            (click)="startGame()">
            Free Play
        </button>
        <button class="btn btn-primary mt-3 mx-2 flex-grow-1"
            *ngIf="!boardRetrievingService.isLoadingBoard$() && boardStateService.gameCompleted$() && !boardRetrievingService.isPlayingDailyBoard$()"
            (click)="startGame()">
            Next Board
        </button>
    </div>

    <!-- More Tutorials or Play Live Boards -->
    <div class="clear-board-container d-flex justify-content-center"
        *ngIf="boardRetrievingService.isTutorialActive$() && !boardSavingService.isSaving$()">
        <button class="btn btn-secondary mt-3 mx-2 flex-grow-1"
            *ngIf="!boardRetrievingService.isLoadingBoard$() && boardStateService.gameCompleted$()"
            (click)="startTutorial()">
            Another Tutorial
        </button>
        <button class="btn btn-primary mt-3 mx-2 flex-grow-1"
            *ngIf="!boardRetrievingService.isLoadingBoard$() && boardStateService.gameCompleted$()"
            (click)="returnHome()">
            I'm ready!
        </button>
    </div>

    <div class="clear-board-container d-flex justify-content-center" *ngIf="boardSavingService.isSaving$()">
        <div class="spinner-container text-center">
            <div class="spinner spinner-sm"></div>
            <small>Saving results...</small>
        </div>
    </div>

</div>

<!-- Stats Modal -->
<div class="modal fade" id="statsModal" tabindex="-1" aria-labelledby="statsModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="statsModalLabel">Board Stats - #{{boardStateService.shareId$()}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">You</th>
                            <th scope="col">Median</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">Time</th>
                            <td>
                                {{ boardSavingService.formatCompletionTime(boardStateService.elapsedTime$()) }}
                                <span
                                    *ngIf="boardStateService.elapsedTime$() <= boardSavingService.stats$().medianCompletionTime"
                                    class="better">🔥</span>
                                <span
                                    *ngIf="boardStateService.elapsedTime$() > boardSavingService.stats$().medianCompletionTime"
                                    class="worse">❄️</span>
                            </td>
                            <td>{{ boardSavingService.stats$().medianCompletionString }}</td>
                        </tr>
                        <tr>
                            <th scope="row">Moves</th>
                            <td>
                                {{ boardStateService.userActions.length - boardStateService.boardSize$() }}
                                <span
                                    *ngIf="boardStateService.userActions.length <= boardSavingService.stats$().medianClicks"
                                    class="better">🔥</span>
                                <span
                                    *ngIf="boardStateService.userActions.length > boardSavingService.stats$().medianClicks"
                                    class="worse">❄️</span>
                            </td>
                            <td>{{ boardSavingService.stats$().medianClicks - boardStateService.boardSize$() | number
                                }}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Clears</th>
                            <td>
                                {{ boardStateService.boardClears$() }}
                                <span
                                    *ngIf="boardStateService.boardClears$() <= boardSavingService.stats$().averageBoardClears"
                                    class="better">🔥</span>
                                <span
                                    *ngIf="boardStateService.boardClears$() > boardSavingService.stats$().averageBoardClears"
                                    class="worse">❄️</span>
                            </td>
                            <td>{{ boardSavingService.stats$().averageBoardClears | number:'1.2-2' }}</td>
                        </tr>

                        <!-- Future use to selectively show percentile if users set that preference. -->
                        <!-- <tr *ngIf="boardSavingService.stats$().userPercentile !== null && 
           (boardSavingService.stats$().userPercentile <= 0.5 || userService.userState().preferences.showPercentile)"> -->

                        <tr
                            *ngIf="boardSavingService.stats$().userPercentile !== null && boardSavingService.stats$().userPercentile <= 0.5">
                            <th scope="row">Percentile</th>
                            <td>
                                <span *ngIf="boardSavingService.stats$().userPercentile === 0" class="fastest">
                                    🏆 Fastest!
                                </span>
                                <span
                                    *ngIf="boardSavingService.stats$().userPercentile > 0 && boardSavingService.stats$().userPercentile <= 0.01">
                                    Top 1% 🔥🔥
                                </span>
                                <span
                                    *ngIf="boardSavingService.stats$().userPercentile > 0.01 && boardSavingService.stats$().userPercentile <= 0.05">
                                    Top 5% 🔥🔥
                                </span>
                                <span
                                    *ngIf="boardSavingService.stats$().userPercentile > 0.05 && boardSavingService.stats$().userPercentile <= 0.1">
                                    Top 10% 🔥🔥
                                </span>
                                <span
                                    *ngIf="boardSavingService.stats$().userPercentile > 0.1 && boardSavingService.stats$().userPercentile <= 0.2">
                                    Top 20% 🔥🔥
                                </span>
                                <span
                                    *ngIf="boardSavingService.stats$().userPercentile > 0.2 && boardSavingService.stats$().userPercentile <= 0.3">
                                    Top 30% 🔥
                                </span>
                                <span
                                    *ngIf="boardSavingService.stats$().userPercentile > 0.3 && boardSavingService.stats$().userPercentile <= 0.4">
                                    Top 40% 🔥
                                </span>
                                <span
                                    *ngIf="boardSavingService.stats$().userPercentile > 0.4 && boardSavingService.stats$().userPercentile <= 0.5">
                                    Top 50%
                                </span>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>

                <p class="text-center mb-0">
                    <strong>{{ boardSavingService.stats$().totalCompletions }}</strong>
                    {{ boardSavingService.stats$().totalCompletions === 1 ? 'person has' : 'people have' }}
                    completed this board.
                </p>

                <div class="star-rating text-center mt-0"
                    *ngIf="!boardRetrievingService.isLoadingBoard$() && boardStateService.gameCompleted$()">
                    <small>Rate this board:</small>
                    <div class="stars">
                        <span *ngFor="let star of [1, 2, 3, 4, 5]; let i = index" (click)="rateBoard(i + 1)"
                            [class.filled]="i + 1 <= boardSavingService.userRating$()">★</span>
                    </div>
                </div>
                <div class="star-rating text-center mt-0"
                    *ngIf="!boardRetrievingService.isLoadingBoard$() && boardStateService.gameCompleted$()">
                    <small>
                        Rate this board 5 stars if you think others would enjoy it!
                    </small>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Toast element -->
<app-toast *ngIf="showToast" [message]="'Board link copied to clipboard!'"></app-toast>