<div class="container mt-5" style="max-width: 320px;">
    <h2 class="text-center mb-4 title-text">Welcome back</h2>

    <!-- Email Form -->
    <form [formGroup]="emailForm" (ngSubmit)="onContinue()">
        <div class="mb-4">
            <input type="email" id="email" class="form-control border-black" formControlName="email"
                placeholder="Enter your email address" [ngClass]="{
                    'is-invalid': emailForm.get('email')?.invalid && emailForm.get('email')?.touched
                }" (input)="clearError('email')" (blur)="onBlur('email')" style="height: 56px;" />
            <div class="invalid-feedback d-block mt-1" *ngIf="showError('email')">
                Please enter a valid email address.
            </div>
        </div>
        <button type="submit" class="btn btn-primary w-100 py-3" style="height: 56px;">Continue</button>
    </form>

    <!-- Sign Up Section -->
    <p class="text-center mt-4 mb-4">
        Don't have an account? <a [routerLink]="'/signup'" class="text-primary text-decoration-none">Sign up</a>
    </p>

    <!-- OR Divider -->
    <div class="d-flex align-items-center my-3">
        <hr class="flex-grow-1" />
        <span class="mx-3 text-muted fw-bold">OR</span>
        <hr class="flex-grow-1" />
    </div>

    <!-- Continue with Google -->
    <button type="button" class="btn btn-outline-dark w-100 d-flex align-items-center justify-content-center mt-4 py-3"
        (click)="continueWithGoogle()" style="justify-content: flex-start; gap: 10px; height: 56px;">
        <span style="display: flex; align-items: center; width: 20px; height: 20px;">
            <img src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 48 48'%3E%3Cdefs%3E%3Cpath id='a' d='M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z'/%3E%3C/defs%3E%3CclipPath id='b'%3E%3Cuse xlink:href='%23a' overflow='visible'/%3E%3C/clipPath%3E%3Cpath clip-path='url(%23b)' fill='%23FBBC05' d='M0 37V11l17 13z'/%3E%3Cpath clip-path='url(%23b)' fill='%23EA4335' d='M0 11l17 13 7-6.1L48 14V0H0z'/%3E%3Cpath clip-path='url(%23b)' fill='%2334A853' d='M0 37l30-23 7.9 1L48 0v48H0z'/%3E%3Cpath clip-path='url(%23b)' fill='%234285F4' d='M48 48L17 24l-4-3 35-10z'/%3E%3C/svg%3E"
                alt="Google Logo" style="width: 100%; height: 100%;" />
        </span>
        Continue with Google
    </button>

    <div *ngIf="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>

    <!-- Agreement Text -->
    <p class="text-center text-muted mt-3">
        By continuing, you agree to the
        <a href="/terms" target="_blank" rel="noopener noreferrer" class="text-primary text-decoration-none">
            Terms of Service
        </a>
        and
        <a href="/privacy" target="_blank" rel="noopener noreferrer" class="text-primary text-decoration-none">
            Privacy Policy
        </a>.
    </p>

</div>