<div class="container mt-4">
    <h2 class="text-center mb-4 title-text">Profile</h2>

    <div class="card mx-auto" style="max-width: 400px;">
        <div class="card-body text-center">
            <!-- Profile Image -->
            <img [src]="userService.userState().profileImageUrl || 'https://cdn.jsdelivr.net/npm/bootstrap-icons/icons/person-circle.svg'"
                class="rounded-circle mb-3" alt="Profile" style="width: 100px; height: 100px;" />

            <!-- Display Name -->
            <div class="d-flex align-items-center justify-content-between mb-3">
                <div class="text-start flex-grow-1">
                    <div class="mb-0">
                        <div class="d-flex align-items-center" style="height: 32px; gap: 8px;">
                            <span>Display Name:</span>
                            <span *ngIf="!isEditingDisplayName()" class="fw-bold">{{ userService.userState().displayName
                                }}</span>
                            <input *ngIf="isEditingDisplayName()" type="text" [(ngModel)]="editableDisplayName"
                                class="form-control" style="width: 140px; height: 30px;" />
                        </div>
                    </div>
                </div>
                <button class="btn p-0" *ngIf="!isEditingDisplayName()" (click)="toggleEditDisplayName()"
                    aria-label="Edit display name">
                    <i class="bi bi-pencil-square fs-5 text-secondary"></i>
                </button>
                <button *ngIf="isEditingDisplayName()" class="btn btn-primary btn-sm ms-2"
                    (click)="updateDisplayName()">
                    Save
                </button>
            </div>

            <!-- Email Verification Status -->
            <div class="text-start mb-3">
                <div class="mb-0">Email Status:
                    <span class="text-success fw-bold" *ngIf="userService.userState().emailVerified">Verified</span>
                    <span class="text-danger fw-bold" *ngIf="!userService.userState().emailVerified">Unverified</span>
                </div>
            </div>

            <!-- Toggle for Instructions -->
            <div class="form-check form-switch mt-4 mb-4">
                <input class="form-check-input" type="checkbox" id="showInstructionsSwitch"
                    [checked]="!userService.userState().preferences.dismissInstructions"
                    (change)="toggleShowInstructions($event)" />
                <label class="form-check-label" for="showInstructionsSwitch">
                    Show game instructions on startup
                </label>
            </div>

            <!-- Login Provider -->
            <div>
                <p *ngIf="userService.userState().providerId === 'google.com'"
                    class="d-flex align-items-center justify-content-center">
                    Logged in via Google
                    <span style="display: flex; align-items: center; margin-left: 5px; width: 20px; height: 20px;">
                        <img src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 48 48'%3E%3Cdefs%3E%3Cpath id='a' d='M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z'/%3E%3C/defs%3E%3CclipPath id='b'%3E%3Cuse xlink:href='%23a' overflow='visible'/%3E%3C/clipPath%3E%3Cpath clip-path='url(%23b)' fill='%23FBBC05' d='M0 37V11l17 13z'/%3E%3Cpath clip-path='url(%23b)' fill='%23EA4335' d='M0 11l17 13 7-6.1L48 14V0H0z'/%3E%3Cpath clip-path='url(%23b)' fill='%2334A853' d='M0 37l30-23 7.9 1L48 0v48H0z'/%3E%3Cpath clip-path='url(%23b)' fill='%234285F4' d='M48 48L17 24l-4-3 35-10z'/%3E%3C/svg%3E"
                            alt="Google Logo" style="width: 100%; height: 100%;" />
                    </span>
                </p>
            </div>
        </div>
    </div>
</div>