import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-toast',
    standalone: true,
    imports: [CommonModule],
    template: `
    <div class="toast show position-fixed bottom-0 start-50 translate-middle-x" *ngIf="show">
      <div class="toast-body bg-primary text-white d-flex justify-content-between">
        <span>
         {{ message }}
        </span>
        <button type="button" class="btn-close btn-close-white ms-2" aria-label="Close" (click)="show = false"></button>
      </div>
    </div>
  `,
    styles: [`
    .toast { transition: opacity 0.5s; opacity: 1; }
  `]
})
export class ToastComponent {
    @Input() message: string = '';
    show = true;

    close() {
        this.show = false;
    }
}
