import { Injectable, signal } from '@angular/core';

import { Color } from '../_models/board.model';
import { COLORS } from '../_models/color.constants';
import { BoardSavingService } from './board-saving.service';
import { HintService } from './hint.service';

type CellState = 'empty' | 'eliminated' | 'diamond';

@Injectable({
  providedIn: 'root'
})
export class BoardStateService {
  private board = signal<number[][]>([]);
  private boardId = signal<string>('');
  private shareId = signal<string>('');
  private boardSize = signal<number>(8);
  private complexityRating = signal<number>(0);
  private createdAt = signal<Date>(new Date());
  private isFormattingBoard = signal<boolean>(false);
  private isGameStarted = signal<boolean>(false);
  private boardStyle = signal<{ backgroundColor: string, borderTop: string, borderRight: string, borderBottom: string, borderLeft: string }[][]>([]);
  private boardState = signal<CellState[][]>([]);
  private incorrectDiamonds = signal<boolean[][]>([]);
  private errorState = signal<boolean[][]>([]);
  private startTime: number = 0;
  private timer: number = 0;
  private elapsedTime = signal<number>(0);
  private isTimerPaused = signal<boolean>(false);
  private gameCompleted = signal<boolean>(false);
  private colorEliminationPending = new Set<number>();
  private fullyEliminatedColors = signal<Set<number>>(new Set());
  private rowEliminationPending = new Set<number>();
  private colEliminationPending = new Set<number>();
  private fullyEliminatedRows = signal<Set<number>>(new Set());
  private fullyEliminatedCols = signal<Set<number>>(new Set());
  private boardSnapshot: CellState[][] | null = null;
  private boardStyleSnapshot: { backgroundColor: string, borderTop: string, borderRight: string, borderBottom: string, borderLeft: string }[][] | null = null;
  private autoEliminateCells = signal<boolean>(true);

  private colors: Color[] = COLORS;

  userActions: { action: string, cell: string, solved_at: Date }[] = [];
  private boardClears = signal<number>(0);
  autoEliminatedCells: Record<string, Set<string>> = {};

  readonly board$ = this.board.asReadonly();
  readonly boardId$ = this.boardId.asReadonly();
  readonly shareId$ = this.shareId.asReadonly();
  readonly boardSize$ = this.boardSize.asReadonly();
  readonly complexityRating$ = this.complexityRating.asReadonly();
  readonly createdAt$ = this.createdAt.asReadonly();
  readonly isFormattingBoard$ = this.isFormattingBoard.asReadonly();
  readonly isGameStarted$ = this.isGameStarted.asReadonly();
  readonly boardStyle$ = this.boardStyle.asReadonly();
  readonly boardState$ = this.boardState.asReadonly();
  readonly incorrectDiamonds$ = this.incorrectDiamonds.asReadonly();
  readonly errorState$ = this.errorState.asReadonly();
  readonly elapsedTime$ = this.elapsedTime.asReadonly();
  readonly gameCompleted$ = this.gameCompleted.asReadonly();
  readonly autoEliminateCells$ = this.autoEliminateCells.asReadonly();
  readonly boardClears$ = this.boardClears.asReadonly();

  constructor(
    private readonly boardSavingService: BoardSavingService,
    private readonly hintService: HintService
  ) { }

  startGame(board: number[][], boardId: string, shareId: string, boardSize: number, complexityRating: number, createdAt: Date): void {
    this.isFormattingBoard.set(true);
    // assign the board, boardId, shareId, boardSize, complexityRating, and createdAt to the signals
    this.board.set(board);
    this.boardId.set(boardId);
    this.shareId.set(shareId);
    this.boardSize.set(boardSize);
    this.complexityRating.set(complexityRating);
    this.createdAt.set(createdAt);

    // calculate the board styles
    this.boardStyle.set(this.calculateBoardStyles(board));

    // Initialize the game board
    this.initializeGameBoard(boardSize);

    this.isGameStarted.set(true);
    this.isFormattingBoard.set(false);
    this.startTimer();
  }

  calculateBoardStyles(board: number[][]): { backgroundColor: string, borderTop: string, borderRight: string, borderBottom: string, borderLeft: string }[][] {
    return board.map((row, rowIndex) =>
      row.map((cell, colIndex) => {
        const color = this.getColorById(cell)?.value ?? 'transparent';
        const { borderTop, borderRight, borderBottom, borderLeft } = this.calculateBorders(rowIndex, colIndex, board);
        return { backgroundColor: color, borderTop, borderRight, borderBottom, borderLeft };
      })
    );
  }

  getColorById(id: number): Color | undefined {
    return this.colors.find(color => color.id === id);
  }

  calculateBorders(rowIndex: number, colIndex: number, board: number[][]) {
    const isLastRow = rowIndex === board.length - 1;
    const isLastCol = colIndex === board[0].length - 1;

    // Apply top border unless it’s the first row or shares a color with the cell above
    const top = rowIndex === 0 || board[rowIndex][colIndex] !== board[rowIndex - 1][colIndex] ? '2px solid black' : '1px solid black';

    // Apply left border unless it’s the first column or shares a color with the cell to the left
    const left = colIndex === 0 || board[rowIndex][colIndex] !== board[rowIndex][colIndex - 1] ? '2px solid black' : '1px solid black';

    // Apply right border only for the last column
    const right = isLastCol ? '2px solid black' : 'none';

    // Apply bottom border only for the last row
    const bottom = isLastRow ? '2px solid black' : 'none';

    // For the bottom-right cell, apply all borders
    if (isLastRow && isLastCol) {
      return { borderTop: top, borderRight: '2px solid black', borderBottom: '2px solid black', borderLeft: left };
    }

    return { borderTop: top, borderRight: right, borderBottom: bottom, borderLeft: left };
  }

  initializeGameBoard(boardSize: number): void {
    this.boardState.set(
      Array.from({ length: boardSize }, () => Array(boardSize).fill('empty'))
    );
    this.incorrectDiamonds.set(
      Array.from({ length: boardSize }, () => Array(boardSize).fill(false))
    );
    this.errorState.set(
      Array.from({ length: boardSize }, () => Array(boardSize).fill(false))
    );
  }

  clearBoardState(): void {
    this.boardState.set(
      Array.from({ length: this.boardSize() }, () => Array(this.boardSize()).fill('empty'))
    );
    // Reset the incorrectDiamonds array
    this.incorrectDiamonds.set(
      Array.from({ length: this.boardSize() }, () => Array(this.boardSize()).fill(false))
    );
    // Reset the errorState array
    this.errorState.set(
      Array.from({ length: this.boardSize() }, () => Array(this.boardSize()).fill(false))
    );
  }

  resetBoardState(): void {
    this.board.set([]);
    this.boardId.set('');
    this.shareId.set('');
    this.boardSize.set(8);
    this.complexityRating.set(0);
    this.createdAt.set(new Date());
    this.isFormattingBoard.set(false);
    this.isGameStarted.set(false);
    this.boardStyle.set([]);
    this.boardState.set([]);
    this.incorrectDiamonds.set([]);
    this.errorState.set([]);
    this.gameCompleted.set(false);
    this.userActions = [];
    this.boardClears.set(0);
  }

  cycleCellState(row: number, col: number): void {
    this.hintService.clearHint(); // Clear the hint when a cell is clicked
    const cellKey = `${row}-${col}`;
    const currentTime = new Date(); // Capture the timestamp of the action

    this.boardState.update((prevState) => {
      const newState = prevState.map(row => row.slice()); // Shallow copy of the board

      switch (newState[row][col]) {
        case 'empty':
          newState[row][col] = 'eliminated'; // First click/tap: mark as eliminated
          this.userActions.push({ action: 'eliminated', cell: cellKey, solved_at: currentTime });
          break;

        case 'eliminated':
          newState[row][col] = 'diamond'; // Second click/tap: mark as diamond
          this.userActions.push({ action: 'diamond', cell: cellKey, solved_at: currentTime });
          if (this.autoEliminateCells()) {
            this.autoEliminatedCells[cellKey] = new Set();
            this.markRowColAndImmediateDiagonalsAsEliminated(row, col, newState, this.autoEliminatedCells[cellKey]);
            this.markColorBlockAsEliminated(row, col, newState, this.autoEliminatedCells[cellKey]);
          }
          break;

        case 'diamond':
          newState[row][col] = 'empty'; // Third click/tap: reset to empty
          this.userActions.push({ action: 'reset', cell: cellKey, solved_at: currentTime });
          this.rollbackAutoEliminatedCells(row, col, newState);
          break;
      }

      return newState;
    });

    this.confirmColorElimination();
    // Run the color elimination check after updating the board state
    this.checkColorElimination();

    this.confirmRowColumnElimination();
    // Run the row/column elimination check after updating the board state
    this.checkRowColumnElimination();

    this.checkAllIncorrectDiamonds(); // Recheck incorrect flags for all diamonds

    if (this.checkGameCompletion()) {
      const roundedElapsedTime = Math.round(this.elapsedTime());
      this.boardSavingService.postCompletionData(this.boardId(), roundedElapsedTime, this.userActions, this.boardClears());
    }
  }

  markRowColAndImmediateDiagonalsAsEliminated(row: number, col: number, newState: CellState[][], autoEliminatedSet: Set<string>): void {
    for (let i = 0; i < this.boardSize(); i++) {
      if (newState[row][i] === 'empty') {
        newState[row][i] = 'eliminated';
        autoEliminatedSet.add(`${row}-${i}`);
      }

      if (newState[i][col] === 'empty') {
        newState[i][col] = 'eliminated';
        autoEliminatedSet.add(`${i}-${col}`);
      }
    }

    const diagonalOffsets = [
      [-1, -1], [-1, 1],
      [1, -1], [1, 1]
    ];

    for (const [dRow, dCol] of diagonalOffsets) {
      const newRow = row + dRow;
      const newCol = col + dCol;
      if (newRow >= 0 && newRow < this.boardSize() && newCol >= 0 && newCol < this.boardSize() && newState[newRow][newCol] === 'empty') {
        newState[newRow][newCol] = 'eliminated';
        autoEliminatedSet.add(`${newRow}-${newCol}`);
      }
    }
  }

  markColorBlockAsEliminated(row: number, col: number, newState: CellState[][], autoEliminatedSet: Set<string>): void {
    const colorId = this.board()[row][col];

    for (let r = 0; r < this.boardSize(); r++) {
      for (let c = 0; c < this.boardSize(); c++) {
        if (this.board()[r][c] === colorId && !(r === row && c === col) && newState[r][c] === 'empty') {
          newState[r][c] = 'eliminated';
          autoEliminatedSet.add(`${r}-${c}`);
        }
      }
    }
  }

  rollbackAutoEliminatedCells(row: number, col: number, newState: CellState[][]): void {
    const cellKey = `${row}-${col}`;
    if (this.autoEliminatedCells[cellKey]) {
      this.autoEliminatedCells[cellKey].forEach((cell) => {
        const [r, c] = cell.split('-').map(Number);

        // Only rollback to 'empty' if the cell is currently 'eliminated'
        if (newState[r][c] === 'eliminated') {
          newState[r][c] = 'empty';
        }
      });

      // Clean up the auto-eliminated tracking for this cell
      delete this.autoEliminatedCells[cellKey];
    }
  }

  checkColorElimination(): void {
    // Filter colors to only include the first `boardSize` colors
    const activeColors = this.colors.slice(0, this.boardSize());

    activeColors.forEach(color => {
      const colorId = color.id;
      const cellsOfColor = this.getCellsByColor(colorId);
      const allEliminated = cellsOfColor.every(({ row, col }) => this.boardState()[row][col] === 'eliminated');

      if (allEliminated) {
        this.colorEliminationPending.add(colorId); // Flag the color as potentially eliminated
      } else {
        this.colorEliminationPending.delete(colorId); // Reset if it's no longer eliminated
      }
    });
  }

  confirmColorElimination(): void {
    this.colorEliminationPending.forEach(colorId => {
      const cellsOfColor = this.getCellsByColor(colorId);
      const allEliminated = cellsOfColor.every(({ row, col }) => this.boardState()[row][col] === 'eliminated');

      if (allEliminated) {
        // Confirm color as fully eliminated and keep it flagged
        this.fullyEliminatedColors.update(set => set.add(colorId));
        // mark the positions of the color that is eliminated as true in the error state
        cellsOfColor.forEach(({ row, col }) => {
          this.errorState.update(state => {
            state[row][col] = true;
            return state;
          });
        }
        );
      } else {
        // If color is no longer fully eliminated, remove it from the flagged colors
        this.fullyEliminatedColors.update(set => {
          set.delete(colorId);
          return set;
        });
        this.colorEliminationPending.delete(colorId);
        // clear the positions of the color that is eliminated as true in the error state
        cellsOfColor.forEach(({ row, col }) => {
          this.errorState.update(state => {
            state[row][col] = false;
            return state;
          });
        }
        );
      }
    });
  }

  // Helper function to retrieve cells by color
  getCellsByColor(colorId: number): { row: number, col: number }[] {
    const cells: { row: number, col: number }[] = [];
    for (let row = 0; row < this.boardSize(); row++) {
      for (let col = 0; col < this.boardSize(); col++) {
        if (this.board()[row][col] === colorId) {
          cells.push({ row, col });
        }
      }
    }
    return cells;
  }

  checkRowColumnElimination(): void {
    const size = this.boardSize();

    // Check each row
    for (let row = 0; row < size; row++) {
      const allEliminated = this.boardState()[row].every(cell => cell === 'eliminated');
      const hasDiamond = this.boardState()[row].includes('diamond');
      if (allEliminated && !hasDiamond) {
        this.rowEliminationPending.add(row); // Flag the row as potentially eliminated
      } else {
        this.rowEliminationPending.delete(row); // Reset if no longer valid
      }
    }

    // Check each column
    for (let col = 0; col < size; col++) {
      const allEliminated = Array.from({ length: size }, (_, row) => this.boardState()[row][col]).every(cell => cell === 'eliminated');
      const hasDiamond = Array.from({ length: size }, (_, row) => this.boardState()[row][col]).includes('diamond');
      if (allEliminated && !hasDiamond) {
        this.colEliminationPending.add(col); // Flag the column as potentially eliminated
      } else {
        this.colEliminationPending.delete(col); // Reset if no longer valid
      }
    }
  }

  confirmRowColumnElimination(): void {
    this.rowEliminationPending.forEach(row => {
      const allEliminated = this.boardState()[row].every(cell => cell === 'eliminated');
      const hasDiamond = this.boardState()[row].includes('diamond');
      if (allEliminated && !hasDiamond) {
        this.fullyEliminatedRows.update(set => set.add(row));
        // mark the positions of the row that is eliminated as true in the error state
        this.errorState.update(state => {
          state[row].fill(true);
          return state;
        });
      } else {
        // If row is no longer fully eliminated, remove it from the flagged rows
        this.errorState.update(state => {
          state[row].fill(false);
          return state;
        });
        this.fullyEliminatedRows.update(set => {
          set.delete(row);
          return set;
        });
      }
    });

    this.colEliminationPending.forEach(col => {
      const allEliminated = Array.from({ length: this.boardSize() }, (_, row) => this.boardState()[row][col]).every(cell => cell === 'eliminated');
      const hasDiamond = Array.from({ length: this.boardSize() }, (_, row) => this.boardState()[row][col]).includes('diamond');
      if (allEliminated && !hasDiamond) {
        this.fullyEliminatedCols.update(set => set.add(col));
        // mark the positions of the column that is eliminated as true in the error state
        this.errorState.update(state => {
          state.forEach(row => row[col] = true);
          return state;
        });
      } else {
        this.errorState.update(state => {
          state.forEach(row => row[col] = false);
          return state;
        });
        this.fullyEliminatedCols.update(set => {
          set.delete(col);
          return set;
        });
      }
    });
  }

  checkAllIncorrectDiamonds(): void {
    this.incorrectDiamonds.update(() => {
      const newState = this.createEmptyIncorrectDiamondsState();

      // Mark incorrect diamonds
      this.markIncorrectDiamonds(newState);

      // Mark multiple diamonds in the same color block
      this.markMultipleDiamondsInColorBlocks(newState);

      return newState;
    });
  }

  // Step 1: Create a cleared state for incorrect diamonds
  private createEmptyIncorrectDiamondsState(): boolean[][] {
    return Array.from({ length: this.boardSize() }, () =>
      Array(this.boardSize()).fill(false)
    );
  }

  // Step 2: Mark incorrect diamonds on the board
  private markIncorrectDiamonds(newState: boolean[][]): void {
    for (let row = 0; row < this.boardSize(); row++) {
      for (let col = 0; col < this.boardSize(); col++) {
        if (this.boardState()[row][col] === 'diamond' && this.isDiamondIncorrect(row, col)) {
          newState[row][col] = true;
        }
      }
    }
  }

  // Step 3: Mark multiple diamonds in the same color block
  private markMultipleDiamondsInColorBlocks(newState: boolean[][]): void {
    const colorIdsChecked = new Set<number>();

    for (let row = 0; row < this.boardSize(); row++) {
      for (let col = 0; col < this.boardSize(); col++) {
        const colorId = this.board()[row][col];

        // Skip already checked color blocks
        if (!colorIdsChecked.has(colorId)) {
          const diamondsInBlock = this.getDiamondsInColorBlock(colorId);

          if (diamondsInBlock.length > 1) {
            diamondsInBlock.forEach(({ row, col }) => {
              newState[row][col] = true;
            });
          }

          colorIdsChecked.add(colorId);
        }
      }
    }
  }

  getDiamondsInColorBlock(colorId: number): { row: number, col: number }[] {
    const diamondsInBlock: { row: number, col: number }[] = [];

    for (let row = 0; row < this.boardSize(); row++) {
      for (let col = 0; col < this.boardSize(); col++) {
        if (this.board()[row][col] === colorId && this.boardState()[row][col] === 'diamond') {
          diamondsInBlock.push({ row, col });
        }
      }
    }

    return diamondsInBlock;
  }

  // Helper method to check the incorrect placement logic
  isDiamondIncorrect(row: number, col: number): boolean {
    let hasConflict = false;

    // Check row conflicts
    for (let i = 0; i < this.boardSize(); i++) {
      if (i !== col && this.boardState()[row][i] === 'diamond') {
        this.markBothDiamondsAsIncorrect(row, i, row, col);
        hasConflict = true;
      }
    }

    // Check column conflicts
    for (let i = 0; i < this.boardSize(); i++) {
      if (i !== row && this.boardState()[i][col] === 'diamond') {
        this.markBothDiamondsAsIncorrect(i, col, row, col);
        hasConflict = true;
      }
    }

    // Check diagonal conflicts
    const diagonalOffsets = [
      [-1, -1], [-1, 1],
      [1, -1], [1, 1]
    ];

    for (const [dRow, dCol] of diagonalOffsets) {
      const newRow = row + dRow;
      const newCol = col + dCol;
      if (
        newRow >= 0 && newRow < this.boardSize() &&
        newCol >= 0 && newCol < this.boardSize() &&
        this.boardState()[newRow][newCol] === 'diamond'
      ) {
        this.markBothDiamondsAsIncorrect(newRow, newCol, row, col);
        hasConflict = true;
      }
    }

    return hasConflict;
  }

  markBothDiamondsAsIncorrect(row1: number, col1: number, row2: number, col2: number): void {
    this.incorrectDiamonds.update((prevState) => {
      const newState = prevState.map(row => [...row]); // Shallow copy

      newState[row1][col1] = true;
      newState[row2][col2] = true;

      return newState;
    });
  }

  checkGameCompletion(): boolean {
    const size = this.boardSize();
    const rowTracker: boolean[] = new Array(size).fill(false);
    const colTracker: boolean[] = new Array(size).fill(false);
    const colorTracker: Record<number, boolean> = {};

    for (let row = 0; row < size; row++) {
      for (let col = 0; col < size; col++) {
        if (this.boardState()[row][col] === 'diamond') {
          if (
            !this.checkRowAndColumn(row, col, rowTracker, colTracker) ||
            !this.checkColorZone(row, col, colorTracker) ||
            this.hasDiagonalConflict(row, col)
          ) {
            return false;
          }
        }
      }
    }

    if (
      rowTracker.every(Boolean) &&
      colTracker.every(Boolean) &&
      Object.keys(colorTracker).length === size
    ) {
      this.completeGame();
      return true;
    }

    return false;
  }

  private checkRowAndColumn(
    row: number,
    col: number,
    rowTracker: boolean[],
    colTracker: boolean[]
  ): boolean {
    if (rowTracker[row] || colTracker[col]) {
      return false;
    }
    rowTracker[row] = true;
    colTracker[col] = true;
    return true;
  }

  private checkColorZone(
    row: number,
    col: number,
    colorTracker: Record<number, boolean>
  ): boolean {
    const colorId = this.board()[row][col];
    if (colorTracker[colorId]) {
      return false; // Duplicate diamond in the same color zone
    }
    colorTracker[colorId] = true;
    return true;
  }

  private hasDiagonalConflict(row: number, col: number): boolean {
    const diagonals = [
      [row - 1, col - 1],
      [row - 1, col + 1],
      [row + 1, col - 1],
      [row + 1, col + 1]
    ];

    return diagonals.some(([r, c]) => this.isDiamondAt(r, c));
  }

  private completeGame(): void {
    this.stopTimer();
    this.gameCompleted.set(true);
  }

  // Helper method to check if there is a diamond at the given position
  isDiamondAt(row: number, col: number): boolean {
    const size = this.boardSize();
    if (row < 0 || col < 0 || row >= size || col >= size) return false; // Out of bounds
    return this.boardState()[row][col] === 'diamond';
  }

  pauseTimer(): void {
    if (this.isTimerPaused()) return; // Avoid redundant pausing

    this.isTimerPaused.set(true);

    // Store a snapshot of the current board state and styles
    this.boardSnapshot = this.boardState();
    this.boardStyleSnapshot = this.boardStyle();

    // Set the board to an empty state (all cells white)
    const emptyBoardState = Array.from({ length: this.boardSize() }, () => Array(this.boardSize()).fill('empty'));
    const emptyBoardStyle = Array.from({ length: this.boardSize() }, () =>
      Array(this.boardSize()).fill({ backgroundColor: 'white', borderTop: '1px solid black', borderRight: '1px solid black', borderBottom: '1px solid black', borderLeft: '1px solid black' })
    );

    this.boardState.set(emptyBoardState);
    this.boardStyle.set(emptyBoardStyle);

    // Save the current elapsed time up to this pause
    const now = performance.now();
    this.elapsedTime.set(now - this.startTime);

    // Stop the timer interval to prevent it from updating
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = 0;
    }
  }

  resumeTimer(): void {
    if (!this.isTimerPaused()) return; // Avoid redundant resuming

    this.isTimerPaused.set(false);

    // Restore the board state and styles from the snapshot
    if (this.boardSnapshot && this.boardStyleSnapshot) {
      this.boardState.set(this.boardSnapshot);
      this.boardStyle.set(this.boardStyleSnapshot);

      // Clear the snapshots
      this.boardSnapshot = null;
      this.boardStyleSnapshot = null;
    }

    // Adjust start time to account for the already elapsed time
    this.startTime = performance.now() - this.elapsedTime();

    // Restart the timer interval
    this.timer = window.setInterval(() => {
      if (!this.isTimerPaused()) {
        const now = performance.now();
        const elapsed = now - this.startTime;
        this.elapsedTime.set(elapsed);
      }
    }, 1000);
  }

  startTimer(): void {
    this.startTime = performance.now();
    this.elapsedTime.set(0);

    this.timer = window.setInterval(() => {
      if (!this.isTimerPaused() && !this.gameCompleted()) { // Check if the game is completed
        const now = performance.now();
        const elapsed = now - this.startTime;
        this.elapsedTime.set(elapsed);
      }
    }, 1000);
  }

  stopTimer(): void {
    if (this.timer !== null) {
      clearInterval(this.timer); // Clear the timer interval
      this.timer = 0; // Reset timer ID
    }
  }

  toggleAutoEliminateCells(isEnabled: boolean): void {
    this.autoEliminateCells.set(isEnabled);
  }

  clearBoard(): void {
    // add a board clear
    this.boardClears.update((prev) => prev + 1);

    // Log the clear action with a timestamp
    const currentTime = new Date();
    this.userActions.push({
      action: 'clear', // Action type for board clear
      cell: 'CLEAR', // Use a special identifier since clears are not associated with a specific cell
      solved_at: currentTime,
    });

    // reset the boardState
    this.clearBoardState();
  }

  setupCompletedBoard(
    board: number[][],
    boardId: string,
    shareId: string,
    boardSize: number,
    complexityRating: number,
    createdAt: Date,
    completionTime: number
  ): void {
    this.isFormattingBoard.set(true);

    // Assign the board, boardId, shareId, boardSize, complexityRating, and createdAt to the signals
    this.board.set(board);
    this.boardId.set(boardId);
    this.shareId.set(shareId);
    this.boardSize.set(boardSize);
    this.complexityRating.set(complexityRating);
    this.createdAt.set(createdAt);

    // Calculate and set board styles
    this.boardStyle.set(this.calculateBoardStyles(board));

    // Initialize game board state without starting the game or timer
    this.initializeGameBoard(boardSize);

    // Set elapsed time to the completion time
    this.setElapsedTime(completionTime);

    this.isGameStarted.set(true);

    // Mark formatting as complete and game as completed
    this.isFormattingBoard.set(false);
    this.isGameStarted.set(false);
    this.gameCompleted.set(true); // Mark the game as completed
  }

  setCellState(row: number, col: number, state: 'empty' | 'eliminated' | 'diamond'): void {
    this.boardState.update(board => {
      const newBoard = board.map(row => [...row]);
      newBoard[row][col] = state;
      return newBoard;
    });
  }

  setGameCompleted(): void {
    this.gameCompleted.set(true);
  }

  setElapsedTime(time: number): void {
    this.elapsedTime.set(time);
  }

  setPreviousUserActions(actions: { action: string, cell: string, solved_at: Date }[]): void {
    this.userActions = actions;
  }

  eliminateAllCells(): void {
    const size = this.boardSize();

    this.boardState.update((prevState) => {
      const newState = prevState.map(row => [...row]); // Copy the current board state

      for (let row = 0; row < size; row++) {
        for (let col = 0; col < size; col++) {
          // Eliminate empty cells (e.g., cells that should be auto-eliminated in this game type)
          if (newState[row][col] === 'empty') {
            newState[row][col] = 'eliminated';
          }
        }
      }

      return newState;
    });
  }

}
