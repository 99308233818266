import { HintRule } from './hint-rule.interface';
import { getColorLabelById } from '../_helpers/color-utils';

export class SingleCellColorRule implements HintRule {
    getHint(boardState: string[][], board: number[][], hintStep: number): string | null {
        const colorCounts: Record<number, { count: number; row: number; col: number }> = {};

        for (let row = 0; row < boardState.length; row++) {
            for (let col = 0; col < boardState[row].length; col++) {
                const color = board[row][col];
                if (boardState[row][col] === 'empty') {
                    if (!colorCounts[color]) {
                        colorCounts[color] = { count: 0, row, col };
                    }
                    colorCounts[color].count++;
                }
            }
        }

        // Check for colors with only one remaining cell
        const singleCellColor = Object.entries(colorCounts).find(([, { count }]) => count === 1);
        if (singleCellColor) {
            const [colorStr, { row, col }] = singleCellColor;
            const color = parseInt(colorStr, 10);

            switch (hintStep) {
                case 0:
                    return 'Look for any colors that only have one open cell. If there is only one cell left of a color, that cell must be a diamond.';
                case 1:
                    return `Place a diamond in ${getColorLabelById(color)} cell in column ${col + 1} and row ${row + 1}.`;
            }
        }

        // Check for rows where all cells are eliminated except one
        for (let row = 0; row < boardState.length; row++) {
            const emptyCells = boardState[row]
                .map((state, col) => (state === 'empty' ? col : -1))
                .filter((col) => col !== -1);

            if (emptyCells.length === 1) {
                const col = emptyCells[0];
                const color = board[row][col];

                switch (hintStep) {
                    case 0:
                        return 'Look for rows where only one cell is not eliminated. If all but one cell in a row are eliminated, the remaining cell must be a diamond.';
                    case 1:
                        return `Place a diamond in the ${getColorLabelById(color)} cell in column ${col + 1} and row ${row + 1}.`;
                }
            }
        }

        // Check for columns where all cells are eliminated except one
        for (let col = 0; col < boardState[0].length; col++) {
            const emptyCells = boardState
                .map((row, rowIndex) => (row[col] === 'empty' ? rowIndex : -1))
                .filter((row) => row !== -1);

            if (emptyCells.length === 1) {
                const row = emptyCells[0];
                const color = board[row][col];

                switch (hintStep) {
                    case 0:
                        return 'Look for columns where only one cell is not eliminated. If all but one cell in a column are eliminated, the remaining cell must be a diamond.';
                    case 1:
                        return `Place a diamond in the ${getColorLabelById(color)} cell in column ${col + 1} and row ${row + 1}.`;
                }
            }
        }

        return null;
    }
}