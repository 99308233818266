import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  private getKey(userId: string): string {
    return `ftd_${userId}`;
  }

  getLocalStorage(userId: string): any {
    const localStorageKey = this.getKey(userId);
    const storedData = localStorage.getItem(localStorageKey);
    return storedData ? JSON.parse(storedData) : null;
  }

  setLocalStorage(userId: string, data: any): void {
    const localStorageKey = this.getKey(userId);
    localStorage.setItem(localStorageKey, JSON.stringify(data));
  }

  updateLocalStorage(userId: string, updates: any): void {
    const currentData = this.getLocalStorage(userId) || {};
    const updatedData = { ...currentData, ...updates };
    this.setLocalStorage(userId, updatedData);
  }

  getPreferences(userId: string): any {
    const data = this.getLocalStorage(userId);
    return data?.preferences || null;
  }

  setPreferences(userId: string, preferences: any): void {
    this.updateLocalStorage(userId, { preferences });
  }

  getDailyChallengeData(userId: string): any {
    const data = this.getLocalStorage(userId);
    return data?.dailyChallenge || null;
  }

  setDailyChallengeData(userId: string, boardId: string, lastDailyDate: Date,): void {
    const dailyChallengeData = {
      lastDailyDate: lastDailyDate,
      boardId: boardId,
      completed: false, // Placeholder for user results
    };
    this.updateLocalStorage(userId, { dailyChallenge: dailyChallengeData });
  }

}
