import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FirebaseError } from 'firebase/app';

import { LoginService } from '../../../services/login.service';

@Component({
  selector: 'app-login-password',
  standalone: true,
  imports: [CommonModule, RouterModule, ReactiveFormsModule],
  templateUrl: './login-password.component.html',
  styleUrl: './login-password.component.scss'
})
export class LoginPasswordComponent implements OnInit {
  @ViewChild('passwordInput') passwordInput!: ElementRef;

  passwordForm: FormGroup;
  email: string | null = null;
  errorMessage: string = '';
  showPassword: boolean = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly loginService: LoginService,
    private readonly router: Router
  ) {
    this.passwordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  ngOnInit(): void {
    this.email = this.loginService.getEmail(); // Retrieve email from the service
    if (!this.email) {
      this.router.navigate(['/login']); // Redirect back if email is missing
    }
  }

  ngAfterViewInit(): void {
    // Focus the password input field
    this.passwordInput.nativeElement.focus();
  }

  async onContinue(): Promise<void> {
    if (this.passwordForm.valid) {
      try {
        await this.loginService.loginWithPassword(this.passwordForm.value.password);
        this.router.navigate(['/']); // Redirect on success
      } catch (error) {
        console.log(error);
        if (error instanceof FirebaseError) {
          switch (error.code) {
            case 'auth/wrong-password':
            case 'auth/invalid-credential':
              this.errorMessage = 'Incorrect password. Please try again.';
              break;
            case 'auth/user-not-found':
              this.errorMessage = 'No account found with this email. Please sign up first.';
              break;
            case 'auth/too-many-requests':
              this.errorMessage = 'Too many failed attempts. Please try again later.';
              break;
            case 'auth/network-request-failed':
              this.errorMessage = 'Network error. Please check your connection.';
              break;
            case 'auth/user-disabled':
              this.errorMessage = 'This account has been disabled. Contact support for help.';
              break;
            case 'auth/internal-error':
              this.errorMessage = 'An internal error occurred. Please try again later.';
              break;
            default:
              this.errorMessage = 'An unexpected error occurred. Please try again.';
          }
        } else {
          console.error('Unexpected error:', error);
          this.errorMessage = 'An unknown error occurred. Please try again.';
        }
      }
    } else {
      this.errorMessage = 'Please enter a valid password.';
    }
  }

  editEmail(): void {
    this.loginService.editEmail(); // Navigate back to edit email
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword; // Toggle password visibility
  }

  async continueWithGoogle(): Promise<void> {
    try {
      await this.loginService.continueWithGoogle();
    } catch (error) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case 'auth/popup-closed-by-user':
            this.errorMessage = 'Sign-in popup was closed before completing. Please try again.';
            break;
          case 'auth/account-exists-with-different-credential':
            this.errorMessage =
              'This email is already associated with a different login method. Try logging in with that method.';
            break;
          case 'auth/network-request-failed':
            this.errorMessage = 'Network error. Please check your connection.';
            break;
          case 'auth/too-many-requests':
            this.errorMessage = 'Too many failed attempts. Please try again later.';
            break;
          case 'auth/internal-error':
            this.errorMessage = 'An internal error occurred. Please try again later.';
            break;
          default:
            this.errorMessage = 'An unexpected error occurred. Please try again.';
        }
      } else {
        console.error('Unexpected error:', error);
        this.errorMessage = 'An unknown error occurred. Please try again.';
      }
    }
  }
}