import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { Clipboard } from '@angular/cdk/clipboard';
import { Platform } from '@angular/cdk/platform';
import { DateTime } from 'luxon';

import { BoardRetrievingService } from '../../services/board-retrieving.service';
import { BoardStateService } from '../../services/board-state.service';
import { BoardSavingService } from '../../services/board-saving.service';
import { UserService } from '../../services/user.service';
import { BoardComponent } from '../board/board.component';
import { TimerComponent } from '../timer/timer.component';
import { LocalStorageService } from '../../services/local-storage.service';
import { ToastComponent } from '../../_helpers/toast.component';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule, RouterModule, FormsModule, BoardComponent, TimerComponent, ToastComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {

  @Input() shareId: string = '';

  gameType = 'daily';
  showToast = false;

  constructor(
    public readonly boardRetrievingService: BoardRetrievingService,
    public readonly boardStateService: BoardStateService,
    public readonly boardSavingService: BoardSavingService,
    public readonly userService: UserService,
    public readonly localStorageService: LocalStorageService,
    private readonly clipboard: Clipboard,
    private readonly platform: Platform
  ) {
  }

  get dailyChallengeData() {
    if (!this.userService.isDailyChallengeCompleted$()) {
      return null;
    }
    const userId = this.userService.userState().userId;
    return userId ? this.localStorageService.getDailyChallengeData(userId) : null;
  }

  startDailyBoard(): void {
    window.dataLayer.push({
      event: 'start_daily_board'
    });

    if (this.userService.userState().preferences.dismissInstructions) {
      this.boardRetrievingService.startDailyBoard();
    } else {
      this.gameType = 'daily';
    }
  }

  startTutorial(): void {
    window.dataLayer.push({
      event: 'start_tutorial'
    });

    if (this.userService.userState().preferences.dismissInstructions) {
      this.boardRetrievingService.startTutorial();
    } else {
      this.gameType = 'tutorial';
    }
  }

  startGame(): void {
    window.dataLayer.push({
      event: 'start_game',
      shareId: this.shareId
    });

    if (this.userService.userState().preferences.dismissInstructions) {
      this.boardRetrievingService.startGame(this.shareId);
    } else {
      this.gameType = 'free-play';
    }
  }

  closeModal(): void {
    if (this.gameType === 'daily') {
      this.boardRetrievingService.startDailyBoard();
    } else if (this.gameType === 'tutorial') {
      this.boardRetrievingService.startTutorial();
    } else {
      console.log('shareId', this.shareId);
      this.boardRetrievingService.startGame(this.shareId);
    }
  }

  onDismissInstructionsChange(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.userService
      .updatePreference('dismissInstructions', isChecked) // Updated method
      .then(() => {
        console.log('Preference saved successfully.');
      })
      .catch((error) => {
        console.error('Error saving preference:', error);
      });
  }

  getUserPerformanceEmoji(userPercentile: number | null): string {
    if (userPercentile === null || userPercentile === undefined) {
      return ''; // No emoji if no data
    }
    if (userPercentile <= 0.2) {
      return '🔥🔥'; // Top 20%
    } else if (userPercentile <= 0.4) {
      return '🔥'; // 20-40%
    } else if (userPercentile <= 0.6) {
      return ''; // 40-60%
    } else if (userPercentile <= 0.8) {
      return '❄️'; // 60-80%
    } else {
      return '❄️❄️'; // Bottom 20%
    }
  }

  shareDailyBoard(): void {
    window.dataLayer.push({
      event: 'share_daily_board',
      shareId: this.boardStateService.shareId$()
    });

    const dailyChallengeStats = this.dailyChallengeData;

    // Check if dailyChallengeStats exist
    if (!dailyChallengeStats) {
      console.error('No daily challenge data found');
      return;
    }

    // Format elapsed time as m:ss
    const elapsedTime = dailyChallengeStats.completionTime
      ? DateTime.fromMillis(dailyChallengeStats.completionTime).toFormat('m:ss')
      : '--';

    // Format date
    const formattedDate = DateTime.fromISO(dailyChallengeStats.lastDailyDate).toLocaleString(DateTime.DATE_SHORT);

    // Determine user percentile emoji
    const userPercentile = dailyChallengeStats.stats?.userPercentile;
    let percentileEmoji = '';
    if (userPercentile !== undefined) {
      if (userPercentile <= 0.2) {
        percentileEmoji = '🔥🔥'; // Top 20%
      } else if (userPercentile <= 0.4) {
        percentileEmoji = '🔥'; // Top 40%
      }
    }

    // Check if the user beat the bot
    const beatBotText = dailyChallengeStats.additionalStats?.beatComputer ? 'and I beat the Bot!' : '';

    // Prepare share text
    const shareIdText = `FTD ${formattedDate}`;
    const url = `ftdmd.com/daily`;
    const shareText = `${shareIdText}\nI found all the diamonds in ${elapsedTime} ${percentileEmoji} ${beatBotText}\nBeat me @ ${url}`;

    // Share logic
    if (this.platform.IOS || this.platform.ANDROID) {
      // Use native share API if available (for mobile devices)
      if (navigator.share) {
        navigator.share({
          title: 'Find the Diamonds!',
          text: shareText
        }).then(() => {
          console.log('Board shared successfully');
        }).catch((error) => {
          console.error('Error sharing the board:', error);
        });
      } else {
        // Fallback to copying the link to the clipboard on mobile if share API is not available
        this.copyToClipboard(shareText);
      }
    } else {
      // For desktop: Copy the share text (including the link) to clipboard
      this.copyToClipboard(shareText);
    }
  }

  viewDailyBoard(): void {
    this.boardRetrievingService.startDailyBoard(); // Replace this with your actual method to reload the daily board state
  }

  // Helper method to copy text to clipboard
  private copyToClipboard(text: string): void {
    this.clipboard.copy(text);
    this.showToast = true;
    setTimeout(() => this.showToast = false, 3000);  // Auto-hide after 3 seconds
  }

}
