import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Clipboard } from '@angular/cdk/clipboard';
import { Platform } from '@angular/cdk/platform';
import { RouterModule } from '@angular/router';
import { DateTime } from 'luxon';

import { BoardRetrievingService } from '../../services/board-retrieving.service';
import { BoardStateService } from '../../services/board-state.service';
import { BoardSavingService } from '../../services/board-saving.service';
import { HintService } from '../../services/hint.service';
import { ToastComponent } from '../../_helpers/toast.component';
import { UserService } from '../../services/user.service';
import { LocalStorageService } from '../../services/local-storage.service';

@Component({
  selector: 'app-timer',
  standalone: true,
  imports: [CommonModule, ToastComponent, RouterModule],
  templateUrl: './timer.component.html',
  styleUrl: './timer.component.scss'
})
export class TimerComponent {
  showToast = false;
  hintText: string | null = null; // Stores the hint text

  constructor(
    public readonly boardRetrievingService: BoardRetrievingService,
    public readonly boardStateService: BoardStateService,
    public readonly boardSavingService: BoardSavingService,
    private readonly userService: UserService,
    private readonly localStorageService: LocalStorageService,
    public readonly hintService: HintService,
    private readonly clipboard: Clipboard,
    private readonly platform: Platform
  ) { }

  get formattedTime(): string {
    const elapsedTime = this.boardStateService.elapsedTime$();
    const totalSeconds = Math.floor(elapsedTime / 1000); // Convert milliseconds to total seconds
    const minutes = Math.floor(totalSeconds / 60); // Get the number of minutes
    const seconds = totalSeconds % 60; // Get the remaining seconds

    // Format as `MM:SS` without milliseconds
    return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  }

  formattedTimeMessage(): string {
    const elapsedTime = this.boardStateService.elapsedTime$();
    const totalSeconds = Math.floor(elapsedTime / 1000); // Convert milliseconds to total seconds
    const minutes = Math.floor(totalSeconds / 60); // Get the number of minutes
    const seconds = totalSeconds % 60; // Get the remaining seconds

    if (minutes === 0) {
      return `You found all the diamonds in ${seconds} second${seconds > 1 ? 's' : ''}!`;
    } else {
      return `You found all the diamonds in ${minutes} minute${minutes > 1 ? 's' : ''} and ${seconds} second${seconds > 1 ? 's' : ''}!`;
    }
  }

  rateBoard(rating: number): void {
    this.boardSavingService.rateBoard(this.boardStateService.boardId$(), rating); // Call the service method
  }

  get dailyChallengeData() {
    if (!this.userService.isDailyChallengeCompleted$()) {
      return null;
    }
    const userId = this.userService.userState().userId;
    return userId ? this.localStorageService.getDailyChallengeData(userId) : null;
  }

  shareBoard(): void {
    window.dataLayer.push({
      event: 'share_board',
      shareId: this.boardStateService.shareId$()
    });

    const shareId = this.boardStateService.shareId$(); // Get the current boardId
    const elapsedTime = this.formattedTime; // Get the formatted time
    let url = `ftdmd.com/${shareId}`;
    let shareIdText = `FTD #${shareId}`;
    let percentileEmoji = '';
    let beatBotText = '';
    let shareText = '';

    if (this.boardRetrievingService.isPlayingDailyBoard$()) {
      const dailyChallengeStats = this.dailyChallengeData;
      const formattedDate = DateTime.fromISO(dailyChallengeStats.lastDailyDate).toLocaleString(DateTime.DATE_SHORT);
      url = `ftdmd.com/daily`;
      shareIdText = `FTD ${formattedDate}`;
      beatBotText = dailyChallengeStats.additionalStats?.beatComputer ? 'and I beat the Bot!' : '';
      const userPercentile = dailyChallengeStats.stats?.userPercentile;
      if (userPercentile !== undefined) {
        if (userPercentile <= 0.2) {
          percentileEmoji = '🔥🔥'; // Top 20%
        } else if (userPercentile <= 0.4) {
          percentileEmoji = '🔥'; // Top 40%
        }
      }
      shareText = `${shareIdText}\nI found all the diamonds in ${elapsedTime} ${percentileEmoji} ${beatBotText}\nBeat me @ ${url}`;
    } else {
      const userPercentile = this.boardSavingService.stats$().userPercentile;
      if (userPercentile !== undefined) {
        if (userPercentile <= 0.2) {
          percentileEmoji = '🔥🔥'; // Top 20%
        } else if (userPercentile <= 0.4) {
          percentileEmoji = '🔥'; // Top 40%
        }
      }
      shareText = `${shareIdText}\nI found all the diamonds in ${elapsedTime} ${percentileEmoji}\nBeat me @ ${url}`;
    }

    // Determine performance emoji (🔥 for better, ❄️ for worse)
    // const averageTime = this.boardSavingService.stats$().averageCompletionTime;
    // const performanceEmoji = this.boardStateService.elapsedTime$() <= averageTime ? '🔥' : '❄️';

    // Construct share text with 3 lines
    // const shareText = `${shareIdText}\nI found all the diamonds in ${elapsedTime} ${performanceEmoji}\nPlay: ${url}`;

    if (this.platform.IOS || this.platform.ANDROID) {
      // Use native share API if available (for mobile devices)
      if (navigator.share) {
        navigator.share({
          title: 'Find the Diamonds!',
          text: shareText // Text with emoji and formatted time
        }).then(() => {
          console.log('Board shared successfully');
        }).catch((error) => {
          console.error('Error sharing the board:', error);
        });
      } else {
        // Fallback to copying the link to the clipboard on mobile if share API is not available
        this.copyToClipboard(shareText);
      }
    } else {
      // For desktop: Copy the share text (including the link) to clipboard
      this.copyToClipboard(shareText);
    }
  }

  statsButton(): void {
    window.dataLayer.push({
      event: 'view_stats',
      shareId: this.boardStateService.shareId$()
    });
  }

  // Helper method to copy text to clipboard
  private copyToClipboard(text: string): void {
    this.clipboard.copy(text);
    this.showToast = true;
    setTimeout(() => this.showToast = false, 3000);  // Auto-hide after 3 seconds
  }

  startGame(): void {
    window.dataLayer.push({
      event: 'start_game',
    });
    this.boardRetrievingService.startGame();
  }

  startTutorial(): void {
    window.dataLayer.push({
      event: 'start_tutorial'
    });
    this.boardRetrievingService.startTutorial();
  }

  showHint(): void {
    const boardState = this.boardStateService.boardState$();
    const board = this.boardStateService.board$();
    // Record the hint click
    const currentTime = new Date();
    this.boardStateService.userActions.push({
      action: 'hint',
      cell: 'HINT',
      solved_at: currentTime,
    });
    this.hintService.provideHint(boardState, board);
  }

  returnHome(): void {
    this.boardRetrievingService.returnHome();
  }
}
