<header class="border-bottom">
    <div class="container-fluid d-flex align-items-center">

        <div class="d-flex align-items-center" style="width: 33%; justify-content: flex-start;">
            <div class="dropdown">
                <a role="button" class="d-flex align-items-center link-body-emphasis text-decoration-none"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="bi bi-list" style="font-size: 1.5rem;"></i>
                </a>
                <ul class="dropdown-menu text-small shadow">
                    <li><a class="dropdown-item" [routerLink]="'/'" (click)="goToSplashScreen()">Home</a></li>
                    <li><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#settingsModal"
                            (click)="pauseTimer()">Settings</a></li>
                    <li><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#helpModal"
                            (click)="pauseTimer()">Help</a></li>
                </ul>
            </div>
        </div>

        <div class="text-center" style="width: 33%;">
            <img src="find_the_diamonds_logo_256x256.webp" alt="Logo" style="height: 100px;">
        </div>

        <div class="d-flex align-items-center justify-content-end" style="width: 33%;">
            <div class="dropdown">
                <a role="button" class="d-block link-body-emphasis text-decoration-none dropdown-toggle"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <img [src]="userService.profileImageUrl$()" (error)="userService.handleImageError()" alt="Profile"
                        width="32" height="32" class="rounded-circle">
                </a>
                <ul class="dropdown-menu text-small shadow">
                    <li *ngIf="userService.isUserLoggedIn$()"><a class="dropdown-item" role="button"
                            [routerLink]="'/profile'" (click)="clearBoardState()">Profile</a></li>
                    <li *ngIf="userService.isUserLoggedIn$()">
                        <hr class="dropdown-divider">
                    </li>
                    <li>
                        <button class="dropdown-item" *ngIf="!userService.isUserLoggedIn$()" [routerLink]="'/login'"
                            (click)="clearBoardState()">
                            Sign In
                        </button>
                    </li>
                    <li>
                        <button class="dropdown-item" *ngIf="userService.isUserLoggedIn$()" (click)="logout()">
                            Sign Out
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</header>

<!-- Modals for Settings and Help -->
<!-- Settings Modal -->
<div class="modal fade" id="settingsModal" tabindex="-1" aria-labelledby="settingsModalLabel" aria-hidden="true"
    (hidden.bs.modal)="resumeTimer()">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="settingsModalLabel">Settings</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <!-- <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="autoEliminateSwitch"
                        [checked]="boardStateService.autoEliminateCells$()" (change)="toggleAutoEliminateCells($event)">
                    <label class="form-check-label" for="autoEliminateSwitch">Auto-eliminate cells</label>
                </div> -->
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="showInstructionsSwitch"
                        [checked]="!userService.userState().preferences.dismissInstructions"
                        (change)="onShowInstructionsChange($event)">
                    <label class="form-check-label" for="showInstructionsSwitch">
                        Show game instructions on startup
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Help Modal -->
<div class="modal fade" id="helpModal" tabindex="-1" aria-labelledby="helpModalLabel" aria-hidden="true"
    (hidden.bs.modal)="resumeTimer()">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <h5 class="text-center mb-2">Rules</h5>
                <ul class="game-rules">
                    <li>There is exactly one diamond in each:</li>
                    <ul>
                        <li>row</li>
                        <li>column</li>
                        <li>color zone</li>
                    </ul>
                    <li>Diamonds cannot touch other diamonds horizontally, vertically, or diagonally.</li>
                </ul>

                <h5 class="mt-4 mb-2 text-center">How to Play</h5>
                <div class="game-mechanics">
                    <div class="mechanic-item">
                        <span class="icon">✖</span>
                        <span>If you think a cell cannot contain a diamond, click it once to mark it as
                            eliminated.</span>
                    </div>
                    <div class="mechanic-item">
                        <span class="icon">💎</span>
                        <span>If you think a cell contains a diamond, click it a second time to mark it with a
                            diamond.</span>
                    </div>
                    <div class="mechanic-item">
                        <span class="icon">↩️</span>
                        <span>If you've made a mistake, click the cell a third time to clear its marking.</span>
                    </div>
                    <div class="mechanic-item">
                        <span class="icon">❌</span>
                        <span>If the game detects a rule violation, problem cells will show a red.</span>
                    </div>
                    <div class="mechanic-item">
                        <span class="icon">🏆</span>
                        <span>The game will finish automatically when you've successfully mined all the
                            diamonds!</span>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
                    Got it!
                </button>
            </div>
        </div>
    </div>
</div>