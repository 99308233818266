import { Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LoginEmailComponent } from './components/login/login-email/login-email.component';
import { LoginPasswordComponent } from './components/login/login-password/login-password.component';
import { SignupEmailComponent } from './components/login/signup-email/signup-email.component';
import { SignupPasswordComponent } from './components/login/signup-password/signup-password.component';
import { authRedirectGuard } from './guards/auth-redirect.guard';
import { ProfileComponent } from './components/profile/profile.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { TermsComponent } from './components/terms/terms.component';

export const routes: Routes = [
    { path: 'login', component: LoginEmailComponent, canActivate: [authRedirectGuard] },
    { path: 'login/password', component: LoginPasswordComponent, canActivate: [authRedirectGuard] },
    { path: 'signup', component: SignupEmailComponent, canActivate: [authRedirectGuard] },
    { path: 'signup/password', component: SignupPasswordComponent, canActivate: [authRedirectGuard] },
    { path: 'boards/:shareId', component: HomeComponent },
    { path: 'profile', component: ProfileComponent },
    { path: 'privacy', component: PrivacyComponent },
    { path: 'terms', component: TermsComponent },
    { path: 'daily', component: HomeComponent },
    { path: '', component: HomeComponent },
    { path: '**', redirectTo: '', pathMatch: 'full' }
];