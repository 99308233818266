<div class="container mt-5" style="max-width: 320px;">
    <h2 class="text-center mb-4 title-text">Create a password</h2>

    <!-- Email and Edit Section -->
    <div class="mb-4 p-2 rounded border border-black d-flex align-items-center justify-content-between"
        style="height: 56px;">
        <span style="padding-left: 4px;">{{ email }}</span>
        <a role="button" class="text-primary text-decoration-none me-3" (click)="editEmail()">Edit</a>
    </div>

    <!-- Password Form -->
    <form [formGroup]="passwordForm" (ngSubmit)="onContinue()">
        <div class="mb-3">
            <label for="password" class="form-label visually-hidden">Password</label>
            <div class="input-group" style="height: 56px;">
                <input #passwordInput type="{{ showPassword ? 'text' : 'password' }}" id="password"
                    class="form-control border-black border-end-0 rounded-start" formControlName="password"
                    placeholder="Password*" style="height: 100%;"
                    [ngClass]="{'is-invalid': passwordForm.get('password')?.invalid && passwordForm.get('password')?.touched}" />
                <button type="button" class="btn btn-light rounded-end border-black border-start-0 pe-3"
                    (click)="togglePasswordVisibility()" tabindex="-1" style="height: 100%;">
                    <i [ngClass]="showPassword ? 'bi bi-eye-slash' : 'bi bi-eye'"></i>
                </button>
            </div>
            <!-- Password Requirements -->
            <div class="mt-1 text-muted small">
                <ul class="list-unstyled">
                    <li>
                        <i class="bi"
                            [ngClass]="{'bi-check-circle text-success': isPasswordLengthValid(), 'bi-x-circle text-danger': !isPasswordLengthValid()}"></i>
                        At least 8 characters
                    </li>
                    <li>
                        <i class="bi"
                            [ngClass]="{'bi-check-circle text-success': hasPasswordNumeric(), 'bi-x-circle text-danger': !hasPasswordNumeric()}"></i>
                        At least one numeric character
                    </li>
                    <li>
                        <i class="bi"
                            [ngClass]="{'bi-check-circle text-success': hasPasswordNonAlphaNumeric(), 'bi-x-circle text-danger': !hasPasswordNonAlphaNumeric()}"></i>
                        At least one non-alphanumeric character
                    </li>
                    <li>
                        <i class="bi"
                            [ngClass]="{'bi-check-circle text-success': hasPasswordUppercase(), 'bi-x-circle text-danger': !hasPasswordUppercase()}"></i>
                        At least one uppercase character
                    </li>
                </ul>
            </div>
            <!-- Validation Message -->
            <div class="invalid-feedback d-block mt-1"
                *ngIf="passwordForm.get('password')?.invalid && passwordForm.get('password')?.touched">
                Password does not meet the requirements.
            </div>
            <div *ngIf="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>
        </div>

        <!-- Display Name Field -->
        <div class="mb-3">
            <label for="displayName" class="form-label visually-hidden">Display Name</label>
            <div class="input-group" style="height: 56px;">
                <input type="text" id="displayName" formControlName="displayName" class="form-control border-black"
                    placeholder="Display Name (optional)" />
            </div>
            <div class="invalid-feedback d-block mt-1" *ngIf="passwordForm.get('displayName')?.touched">
                Display Name must be 20 characters or fewer.
            </div>
        </div>

        <button type="submit" class="btn btn-primary w-100 py-3" [disabled]="passwordForm.invalid"
            style="height: 56px;">Continue</button>
    </form>

    <!-- Agreement Text -->
    <p class="text-center text-muted mt-3">
        By continuing, you agree to the
        <a href="/terms" target="_blank" rel="noopener noreferrer" class="text-primary text-decoration-none">
            Terms of Service
        </a>
        and
        <a href="/privacy" target="_blank" rel="noopener noreferrer" class="text-primary text-decoration-none">
            Privacy Policy
        </a>.
    </p>
</div>