<div class="container py-4">
    <h1 class="text-center mb-4">Privacy Policy</h1>
    <p class="text-center text-muted mb-4">Last Updated: 18 November 2024</p>
    <div class="row">
        <div class="col-12 col-md-10 offset-md-1">
            <h2 class="h4 mt-4">Information We Collect</h2>
            <ul>
                <li><strong>Personal Information:</strong> When you create an account, we may collect information like
                    your name, email address, and other identifiers.</li>
                <li><strong>Automatically Collected Information:</strong> We collect certain information automatically
                    when you use the game, such as IP address, device information, and usage data.</li>
                <li><strong>In-Game Data:</strong> We collect data related to your game activities, scores, and
                    interactions with other players.</li>
            </ul>

            <h2 class="h4 mt-4">How We Use Your Information</h2>
            <ul>
                <li><strong>To Provide and Improve the Game:</strong> We use the information to maintain, improve, and
                    personalize the game experience.</li>
                <li><strong>For Communication:</strong> We may send emails or notifications related to your account,
                    updates, or promotional offers (with your consent).</li>
                <li><strong>Analytics and Research:</strong> We use aggregated data for internal analysis and research
                    to improve the game.</li>
            </ul>

            <h2 class="h4 mt-4">Sharing Your Information</h2>
            <ul>
                <li><strong>With Service Providers:</strong> We may share your information with third-party service
                    providers who help us with game hosting, analytics, and other services.</li>
                <li><strong>Legal Requirements:</strong> We may disclose information if required by law or to protect
                    our rights or others' safety.</li>
                <li><strong>In the Event of a Sale or Merger:</strong> If we are involved in a merger or acquisition,
                    your information may be transferred to the new entity.</li>
            </ul>

            <h2 class="h4 mt-4">Your Choices and Rights</h2>
            <ul>
                <li><strong>Access and Update Information:</strong> You can update your account information in your
                    settings.</li>
                <li><strong>Delete Account:</strong> You may request account deletion, and we will delete or anonymize
                    your personal data in accordance with legal obligations.</li>
                <li><strong>Opt-Out of Marketing Communications:</strong> You can opt out of receiving promotional
                    emails through the unsubscribe link provided.</li>
            </ul>

            <h2 class="h4 mt-4">Prohibited Automated Access</h2>
            <p>
                <strong>Data Collection and Scraping:</strong> You may not use automated tools, bots, or scrapers to
                access or collect data from the game. Unauthorized automated access to our servers, game data, or other
                systems is strictly prohibited.
            </p>

            <h2 class="h4 mt-4">Security</h2>
            <p>We take reasonable steps to protect your information but cannot guarantee absolute security due to the
                nature of the internet.</p>

            <h2 class="h4 mt-4">Children’s Privacy</h2>
            <p>We do not knowingly collect information from children under the age of 13. If we learn that we have
                collected information from a child without parental consent, we will delete it.</p>

            <h2 class="h4 mt-4">Changes to This Privacy Policy</h2>
            <p>We may update this policy from time to time. You are advised to review it periodically for any changes.
            </p>

            <h2 class="h4 mt-4">Contact Us</h2>
            <p>If you have any questions about this policy, please contact us at <a
                    href="mailto:support@findthediamonds.com">support&#64;findthediamonds.com</a>.</p>
        </div>
    </div>
</div>