import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BoardRetrievingService } from '../../services/board-retrieving.service';
import { BoardStateService } from '../../services/board-state.service';

@Component({
  selector: 'app-board',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './board.component.html',
  styleUrl: './board.component.scss'
})
export class BoardComponent {

  constructor(
    public readonly boardRetrievingService: BoardRetrievingService,
    public readonly boardStateService: BoardStateService
  ) { }

  handleCellClick(row: number, col: number): void {
    // Disable clicks if the game is completed
    if (!this.boardStateService.gameCompleted$()) {
      this.boardStateService.cycleCellState(row, col);
    }
  }

}
