// src/app/_models/color.constants.ts
import { Color } from './board.model';

export const COLORS: Color[] = [
    { id: 1, label: 'Red', value: '#f28b82' },
    { id: 2, label: 'Pink', value: '#ffb6c1' },
    { id: 3, label: 'Orange', value: '#ffbb85' },
    { id: 4, label: 'Yellow', value: '#fff4b5' },
    { id: 5, label: 'Green', value: '#c5e1a5' },
    { id: 6, label: 'Blue', value: '#92c9ff' },
    { id: 7, label: 'Indigo', value: '#a99fe8' },
    { id: 8, label: 'Grey', value: '#d3d3d3' },
    { id: 9, label: 'Brown', value: '#caa472' },
    { id: 10, label: 'Teal', value: '#77c6c3' },
    { id: 11, label: 'Maroon', value: '#c78b96' },
    { id: 12, label: 'Violet', value: '#c5b0e3' },
    { id: 13, label: 'Aqua', value: '#b2e0f1' },
];
