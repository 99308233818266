import { HintRule } from './hint-rule.interface';
import { getColorLabelById } from '../_helpers/color-utils';

export class TouchingCellsRule implements HintRule {
    getHint(boardState: string[][], board: number[][], hintStep: number): string | null {
        const N = board.length;

        for (let row = 0; row < N; row++) {
            for (let col = 0; col < N; col++) {
                if (boardState[row][col] === 'empty') {
                    const colorId = board[row][col];

                    const invalidatedColorId = this.getInvalidatedColor(
                        board,
                        boardState,
                        row,
                        col,
                        N
                    );
                    if (invalidatedColorId !== null) {
                        return this.generateHint(
                            hintStep,
                            row,
                            col,
                            colorId,
                            invalidatedColorId
                        );
                    }
                }
            }
        }

        return null; // No valid hints found
    }

    private getInvalidatedColor(
        board: number[][],
        boardState: string[][],
        row: number,
        col: number,
        N: number
    ): number | null {
        const cellColor = board[row][col];

        // Count available cells by color before simulating the placement
        const remainingBefore = this.countRemainingCellsByColor(board, boardState, N);

        // Simulate eliminating cells if a diamond is placed in the current cell
        const tempBoardState = this.cloneBoardState(boardState);
        this.simulateCellElimination(row, col, tempBoardState, N);

        // Count available cells by color after the simulation
        const remainingAfter = this.countRemainingCellsByColor(board, tempBoardState, N);

        // Find any color that loses all remaining cells after simulation
        for (const color in remainingBefore) {
            if (
                color !== String(cellColor) && // Exclude the current cell's color
                remainingBefore[color] > 0 && // The color had available cells before
                (!remainingAfter.hasOwnProperty(color) || remainingAfter[color] === 0) // No cells left
            ) {
                return parseInt(color, 10); // Return the invalidated color ID
            }
        }

        return null; // No colors were invalidated
    }

    private countRemainingCellsByColor(
        board: number[][],
        boardState: string[][],
        N: number
    ): Record<number, number> {
        const remainingCells: Record<number, number> = {};

        for (let row = 0; row < N; row++) {
            for (let col = 0; col < N; col++) {
                if (boardState[row][col] === 'empty') {
                    const color = board[row][col];
                    remainingCells[color] = (remainingCells[color] || 0) + 1;
                }
            }
        }

        return remainingCells;
    }

    private simulateCellElimination(
        row: number,
        col: number,
        boardState: string[][],
        N: number
    ): void {
        const directions = [
            [0, 1], [1, 0], [0, -1], [-1, 0], // Horizontal & vertical
            [-1, -1], [-1, 1], [1, -1], [1, 1] // Diagonals
        ];

        // Eliminate the entire row, column, and diagonals
        for (let i = 0; i < N; i++) {
            boardState[row][i] = 'eliminated';
            boardState[i][col] = 'eliminated';
        }

        for (const [dRow, dCol] of directions) {
            const newRow = row + dRow;
            const newCol = col + dCol;
            if (newRow >= 0 && newRow < N && newCol >= 0 && newCol < N) {
                boardState[newRow][newCol] = 'eliminated';
            }
        }
    }

    private cloneBoardState(boardState: string[][]): string[][] {
        return boardState.map((row) => [...row]);
    }

    private generateHint(
        hintStep: number,
        row: number,
        col: number,
        colorId: number,
        invalidatedColorId: number
    ): string {
        const invalidatedColorLabel = getColorLabelById(invalidatedColorId);
        switch (hintStep) {
            case 0:
                return `Eliminate any cells that, if that cell were a diamond, would eliminate another color entirely.`;
            case 1:
                return `Placing a diamond in the ${getColorLabelById(colorId)} cell at row ${row + 1
                    } and column ${col + 1} will eliminate all remaining ${invalidatedColorLabel} cells. The ${getColorLabelById(colorId)} cell at row ${row + 1
                    } and column ${col + 1} cannot be a diamond.`;
        }
        return '';
    }
}
