import { HintRule } from './hint-rule.interface';
import { getColorLabelById } from '../_helpers/color-utils';

export class RowOrColViolationRule implements HintRule {
    getHint(boardState: string[][], board: number[][], hintStep: number): string | null {
        const N = board.length;
        const cellsToEliminate = new Set<string>();
        const testedCells = new Set<string>();

        // Derive solvedDiamondPositions
        const solvedDiamondPositions = Array(N).fill(-1);
        for (let row = 0; row < N; row++) {
            for (let col = 0; col < N; col++) {
                if (boardState[row][col] === 'diamond') {
                    solvedDiamondPositions[row] = col; // Mark the column for the diamond in this row
                }
            }
        }

        for (let row = 0; row < N; row++) {
            for (let col = 0; col < N; col++) {
                if (boardState[row][col] === 'empty') {
                    const cellColor = board[row][col];

                    const directions = [
                        [0, 1], [1, 0], [0, -1], [-1, 0],
                        [-1, -1], [-1, 1], [1, -1], [1, 1]
                    ];

                    for (const [dRow, dCol] of directions) {
                        const adjRow = row + dRow;
                        const adjCol = col + dCol;
                        const adjCellKey = `${adjRow},${adjCol}`;

                        if (
                            adjRow < 0 || adjRow >= N ||
                            adjCol < 0 || adjCol >= N ||
                            boardState[adjRow][adjCol] !== 'empty' ||
                            board[adjRow][adjCol] === cellColor ||
                            testedCells.has(adjCellKey)
                        ) {
                            continue;
                        }

                        testedCells.add(adjCellKey);

                        const tempEliminatedCells = this.cloneEliminatedCells(boardState);
                        this.tempEliminateSurroundingCells(adjRow, adjCol, tempEliminatedCells, N);

                        if (this.detectViolation(tempEliminatedCells, N, adjRow, adjCol, solvedDiamondPositions)) {
                            cellsToEliminate.add(adjCellKey);
                        }
                    }
                }
            }
        }

        if (cellsToEliminate.size > 0) {
            const [row, col] = Array.from(cellsToEliminate)[0].split(',').map(Number);
            const color = board[row][col];

            switch (hintStep) {
                case 0:
                    return 'Check if placing a diamond in an empty cell would eliminate all other cells for a row, column or color.';
                case 1:
                    return `Eliminate the ${getColorLabelById(color)} cell at row ${row + 1}, column ${col + 1} because placing a diamond here would violate the one diamond per row, column and colour rules..`;
            }
        }

        return null; // No hints available
    }

    private detectViolation(
        tempEliminatedCells: string[][],
        N: number,
        simulatedRow: number,
        simulatedCol: number,
        solvedDiamondPositions: number[]
    ): boolean {
        for (let row = 0; row < N; row++) {
            if (
                row !== simulatedRow &&
                tempEliminatedCells[row].every(cell => cell === 'eliminated') &&
                solvedDiamondPositions[row] === -1
            ) {
                return true;
            }
        }

        for (let col = 0; col < N; col++) {
            if (
                col !== simulatedCol &&
                tempEliminatedCells.map(row => row[col]).every(cell => cell === 'eliminated') &&
                !solvedDiamondPositions.includes(col)
            ) {
                return true;
            }
        }

        return false;
    }

    private tempEliminateSurroundingCells(row: number, col: number, boardState: string[][], N: number): void {
        const directions = [
            [0, 1], [1, 0], [0, -1], [-1, 0],
            [-1, -1], [-1, 1], [1, -1], [1, 1]
        ];

        for (let i = 0; i < N; i++) {
            boardState[row][i] = 'eliminated';
            boardState[i][col] = 'eliminated';
        }

        for (const [dRow, dCol] of directions) {
            const newRow = row + dRow;
            const newCol = col + dCol;
            if (newRow >= 0 && newRow < N && newCol >= 0 && newCol < N) {
                boardState[newRow][newCol] = 'eliminated';
            }
        }
    }

    private cloneEliminatedCells(boardState: string[][]): string[][] {
        return boardState.map(row => [...row]);
    }
}
