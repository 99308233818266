import { computed, inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { Auth, signInAnonymously, signInWithCredential, signInWithEmailAndPassword, linkWithPopup, GoogleAuthProvider } from '@angular/fire/auth';
import { OAuthProvider, onAuthStateChanged, EmailAuthProvider, linkWithCredential, User, updateProfile } from 'firebase/auth';
import { Firestore, doc, setDoc, getDoc } from '@angular/fire/firestore';
import { FirebaseError } from 'firebase/app';
import { DateTime } from 'luxon';

import { LocalStorageService } from './local-storage.service';
import { UserState, UserPreferences } from '../_models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private firestore = inject(Firestore);

  private auth = inject(Auth); // Inject Auth from AngularFire
  private userId = signal<string>('');
  private isUserLoggedIn = signal<boolean>(false);
  private profileImageUrl = signal<string>('https://cdn.jsdelivr.net/npm/bootstrap-icons/icons/person-circle.svg'); // Default user icon
  private displayName = signal<string>('Guest');
  private emailVerified = signal<boolean>(false);
  private providerId = signal<string>('unknown');
  private tokenExpirationTime = signal<Date | null>(null);
  private userPreferences = signal<UserPreferences>({ dismissInstructions: false });

  private retryCount = 0;
  private maxRetries = 5;
  private retryDelay = 30000;

  // Signal for the last daily challenge date
  private lastDailyChallengeDate = signal<Date | null>(null);
  private dailyChallengeCompleted = signal<boolean>(false);

  // Signal to determine if the last daily challenge is today's
  readonly isTodayDailyChallenge = computed(() => {
    const lastDate = this.lastDailyChallengeDate();
    if (!(lastDate instanceof Date) || isNaN(lastDate.getTime())) {
      return false; // Return false if lastDate is not a valid Date
    }

    const today = new Date();
    return (
      lastDate.getUTCFullYear() === today.getUTCFullYear() &&
      lastDate.getUTCMonth() === today.getUTCMonth() &&
      lastDate.getUTCDate() === today.getUTCDate()
    );
  });

  readonly userState = computed<UserState>(() => ({
    isLoggedIn: this.isUserLoggedIn(),
    userId: this.userId() ?? null,
    profileImageUrl: this.profileImageUrl(),
    displayName: this.displayName(),
    emailVerified: this.emailVerified(),
    providerId: this.providerId(),
    tokenExpirationTime: this.tokenExpirationTime(),
    preferences: this.userPreferences()
  }));

  readonly needsTokenRefresh = computed(() => {
    const expiration = this.tokenExpirationTime();
    return expiration && expiration.getTime() - Date.now() < 5 * 60 * 1000; // Less than 5 minutes
  });

  readonly userId$ = this.userId.asReadonly();
  readonly isUserLoggedIn$ = this.isUserLoggedIn.asReadonly();
  readonly profileImageUrl$ = this.profileImageUrl.asReadonly();
  readonly isDailyChallengeCompleted$ = this.dailyChallengeCompleted.asReadonly();

  constructor(private readonly router: Router, private readonly localStorage: LocalStorageService) {
    this.listenForAuthChanges();
  }

  private listenForAuthChanges() {
    onAuthStateChanged(this.auth, async (user) => {
      if (user) {
        this.userId.set(user.uid);
        this.isUserLoggedIn.set(!user.isAnonymous);

        // Extract display name and email verification status
        this.displayName.set(user.displayName ?? 'Guest');
        this.emailVerified.set(user.emailVerified);

        // Extract provider ID and profile image
        const providerData = user.providerData[0] ?? {};
        this.providerId.set(providerData.providerId ?? 'unknown');
        this.profileImageUrl.set(
          user.photoURL ?? providerData.photoURL ?? 'https://cdn.jsdelivr.net/npm/bootstrap-icons/icons/person-circle.svg'
        );

        // Fetch token expiration time
        const tokenResult = await user.getIdTokenResult();
        this.tokenExpirationTime.set(new Date(tokenResult.expirationTime));

        // Only load preferences if userId is valid
        if (this.userId()) {
          await this.loadPreferences(this.userId());
          await this.loadLastDailyChallengeDate(this.userId());
        }
      } else {
        this.startAnonymousSession();
      }
    });
  }

  private async loadLastDailyChallengeDate(userId: string): Promise<void> {
    const localData = this.localStorage.getLocalStorage(userId) || {};
    const dailyChallenge = localData.dailyChallenge || {};
    const lastDailyDate = dailyChallenge.lastDailyDate
      ? DateTime.fromISO(dailyChallenge.lastDailyDate, { zone: 'America/Los_Angeles' }) // Parse as PST/PDT
      : null;

    this.lastDailyChallengeDate.set(lastDailyDate?.toJSDate() || null);

    // Calculate the current PST/PDT day's midnight and end of day
    const now = DateTime.now().setZone('America/Los_Angeles');
    const startOfDay = now.startOf('day');
    const endOfDay = now.endOf('day');

    // Ensure `isWithinWindow` is boolean
    const isWithinWindow = !!lastDailyDate && lastDailyDate >= startOfDay && lastDailyDate <= endOfDay;

    // Ensure `dailyChallenge.completed` is boolean
    const isCompleted = !!dailyChallenge.completed;

    // Combine them safely
    this.dailyChallengeCompleted.set(isCompleted && isWithinWindow);
  }

  setDailyChallengeCompleted(isCompleted: boolean): void {
    this.dailyChallengeCompleted.set(isCompleted);
  }

  private async loadPreferences(userId: string): Promise<void> {
    if (!userId) {
      console.error('loadPreferences called without a valid userId');
      return; // Exit early if userId is invalid
    }

    const localData = this.localStorage.getLocalStorage(userId) || {};
    const preferences = localData.preferences || {};
    const lastChecked = preferences.lastChecked || 0;

    const oneWeekInMs = 7 * 24 * 60 * 60 * 1000;
    if (Date.now() - lastChecked < oneWeekInMs) {
      this.userPreferences.set(preferences); // Use local preferences if recently checked
    } else {
      try {
        const firestorePreferences = await this.fetchPreferencesFromFirestore(userId);
        this.localStorage.updateLocalStorage(userId, {
          preferences: { ...firestorePreferences, lastChecked: Date.now() },
        });
        this.userPreferences.set(firestorePreferences);
      } catch (error) {
        console.error('Error loading preferences from Firestore:', error);
        this.userPreferences.set(preferences); // Fallback to local preferences on error
      }
    }
  }

  private async fetchPreferencesFromFirestore(userId: string): Promise<UserPreferences> {
    if (!userId) {
      console.error('fetchPreferencesFromFirestore called without a valid userId');
      return { dismissInstructions: false }; // Default preferences
    }

    try {
      const userPreferencesRef = doc(this.firestore, `userPreferences/${userId}`);
      const docSnapshot = await getDoc(userPreferencesRef);

      if (docSnapshot.exists()) {
        return docSnapshot.data() as UserPreferences;
      }

      return { dismissInstructions: false }; // Default preferences if the document doesn't exist
    } catch (error) {
      console.error('Error fetching user preferences from Firestore:', error);
      return { dismissInstructions: false }; // Default preferences in case of an error
    }
  }

  async updatePreference(key: string, value: any): Promise<void> {
    const userId = this.userId();
    if (!userId) {
      throw new Error('User is not logged in.');
    }

    // Update local preferences
    const updatedPreferences = {
      ...this.userPreferences(),
      [key]: value,
    };

    // Save to local storage
    this.localStorage.updateLocalStorage(userId, {
      preferences: { ...updatedPreferences, lastChecked: Date.now() },
    });

    // Update in-memory signal
    this.userPreferences.set(updatedPreferences);

    // Save to Firestore immediately
    try {
      await this.savePreferencesToFirestore(userId, updatedPreferences);
      console.log('Preferences updated successfully in Firestore.');
    } catch (error) {
      console.error('Error updating preferences in Firestore:', error);
    }
  }

  private async savePreferencesToFirestore(userId: string, preferences: UserPreferences): Promise<void> {
    const userPreferencesRef = doc(this.firestore, `userPreferences/${userId}`);
    await setDoc(userPreferencesRef, preferences, { merge: true });
  }

  public getCurrentUser(): User | null {
    return this.auth.currentUser;
  }

  private async startAnonymousSession() {
    try {
      const result = await signInAnonymously(this.auth);
      this.userId.set(result.user.uid);
      this.isUserLoggedIn.set(false);  // Explicitly set as not logged in
      this.displayName.set('Guest');
      this.emailVerified.set(false);
      this.providerId.set('anonymous');
      this.profileImageUrl.set('https://cdn.jsdelivr.net/npm/bootstrap-icons/icons/person-circle.svg');
      const tokenResult = await result.user.getIdTokenResult();
      this.tokenExpirationTime.set(new Date(tokenResult.expirationTime));
    } catch (error) {
      console.error("Anonymous sign-in failed:", error);
    }
  }

  public async linkOrSignInWithGoogle(): Promise<void> {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });

    try {
      await this.handleAnonymousUserLinking(provider);
    } catch (error) {
      if (error instanceof FirebaseError) {
        if (error.code === 'auth/credential-already-in-use') {
          await this.handleCredentialAlreadyInUseError(error);
        } else {
          this.handleFirebaseError(error);
          throw error; // Propagate other errors for further handling
        }
      } else {
        console.error('Unexpected error:', error);
        throw error; // Re-throw non-Firebase errors
      }
    }
  }

  private async handleAnonymousUserLinking(provider: GoogleAuthProvider): Promise<void> {
    const user = this.auth.currentUser;
    if (!user?.isAnonymous) {
      console.warn("No anonymous user to link, or user is already signed in.");
      return;
    }

    const result = await linkWithPopup(user, provider);
    console.log('Anonymous account successfully linked with Google:', result.user);

    this.userId.set(result.user.uid);
    this.isUserLoggedIn.set(true);

    const googleProviderData = result.user.providerData.find(
      (info) => info.providerId === 'google.com'
    );
    this.profileImageUrl.set(
      googleProviderData?.photoURL ?? 'https://cdn.jsdelivr.net/npm/bootstrap-icons/icons/person-circle.svg'
    );
  }

  private async handleCredentialAlreadyInUseError(error: FirebaseError): Promise<void> {
    const credential = OAuthProvider.credentialFromError(error);
    if (!credential) {
      throw error; // Re-throw if no credential is available
    }

    try {
      const signInResult = await signInWithCredential(this.auth, credential);
      console.log('Signed in with existing Google account:', signInResult.user);

      this.userId.set(signInResult.user.uid);
      this.isUserLoggedIn.set(true);

      const googleProviderData = signInResult.user.providerData.find(
        (info) => info.providerId === 'google.com'
      );
      this.profileImageUrl.set(
        googleProviderData?.photoURL ?? 'https://cdn.jsdelivr.net/npm/bootstrap-icons/icons/person-circle.svg'
      );
    } catch (signInError) {
      console.error('Error signing in with existing Google credentials:', signInError);
      throw signInError; // Propagate the error
    }
  }

  private handleFirebaseError(error: FirebaseError): void {
    switch (error.code) {
      case 'auth/popup-closed-by-user':
        console.warn('User closed the popup without completing sign-in.');
        break;
      case 'auth/credential-already-in-use':
        console.warn('Google account is already linked to another user. Signing in instead.');
        break;
      default:
        console.error('Unexpected Firebase error:', error);
    }
  }

  public signOut(): void {
    this.auth.signOut().then(() => {
      this.userId.set(''); // Reset user ID signal
      this.isUserLoggedIn.set(false); // Reset login status signal
      this.profileImageUrl.set('https://cdn.jsdelivr.net/npm/bootstrap-icons/icons/person-circle.svg'); // Reset to default icon
      this.startAnonymousSession(); // Start a new anonymous session on sign-out
      this.router.navigate(['/']); // Navigate to home page
    }).catch(error => {
      console.error("Sign-out error:", error);
    });
  }

  handleImageError() {
    if (this.retryCount >= this.maxRetries) {
      console.warn("Max retries reached. Using placeholder image.");
      this.setDefaultProfileImage();
      return;
    }

    setTimeout(() => {
      this.retryCount++;
      const originalUrl = this.userState().profileImageUrl.split('?')[0];
      this.profileImageUrl.set(`${originalUrl}?retry=${this.retryCount}`);
    }, this.retryDelay);
  }

  private setDefaultProfileImage() {
    this.profileImageUrl.set('https://cdn.jsdelivr.net/npm/bootstrap-icons/icons/person-circle.svg');
  }

  public async getAuthToken(): Promise<string | null> {
    const user = this.auth.currentUser;
    if (this.isUserLoggedIn() && user) {
      // Refresh token if needed
      if (this.needsTokenRefresh()) {
        return user.getIdToken(true);
      }
      return user.getIdToken();
    }
    return null;
  }

  public async loginWithEmailAndPassword(email: string, password: string): Promise<void> {
    try {
      const userCredential = await signInWithEmailAndPassword(this.auth, email, password);
      const user = userCredential.user;

      // Update signals with the logged-in user details
      this.userId.set(user.uid);
      this.isUserLoggedIn.set(true);

      // Optional: Update profile image if available
      const googleProviderData = user.providerData.find(
        (info) => info.providerId === 'google.com'
      );
      this.profileImageUrl.set(
        user.photoURL ?? googleProviderData?.photoURL ?? 'https://cdn.jsdelivr.net/npm/bootstrap-icons/icons/person-circle.svg'
      );

      // Fetch and set token expiration time
      const tokenResult = await user.getIdTokenResult();
      this.tokenExpirationTime.set(new Date(tokenResult.expirationTime));

      console.log('User signed in successfully:', this.userState());
    } catch (error) {
      console.error('Error signing in with email and password:', error);
      throw error; // Rethrow error for component to handle
    }
  }

  public async linkWithEmailAndPassword(email: string, password: string): Promise<void> {
    const user = this.getCurrentUser();
    if (!user?.isAnonymous) {
      // Ensure there's a user, and that the user is anonymous before attempting to upgrade
      throw new Error('No anonymous user available to upgrade.');
    }

    try {
      const credential = EmailAuthProvider.credential(email, password);
      const result = await linkWithCredential(user, credential);

      // Update signals after linking
      this.userId.set(result.user.uid);
      this.isUserLoggedIn.set(true);

      // Optional: Update profile image if available
      const googleProviderData = result.user.providerData.find(
        (info) => info.providerId === 'google.com'
      );
      this.profileImageUrl.set(
        result.user.photoURL ?? googleProviderData?.photoURL ?? 'https://cdn.jsdelivr.net/npm/bootstrap-icons/icons/person-circle.svg'
      );

      // Fetch and set token expiration time
      const tokenResult = await result.user.getIdTokenResult();
      this.tokenExpirationTime.set(new Date(tokenResult.expirationTime));

      console.log('Anonymous account successfully upgraded to email/password:', this.userState());
    } catch (error) {
      console.error('Error upgrading anonymous account:', error);
      throw error; // Rethrow error for the calling service/component
    }
  }

  public async updateDisplayName(newDisplayName: string): Promise<void> {
    const user = this.getCurrentUser();
    if (!user) {
      throw new Error('No user is currently logged in.');
    }

    try {
      await updateProfile(user, { displayName: newDisplayName });
      this.displayName.set(newDisplayName); // Update the signal
    } catch (error) {
      console.error('Error updating display name:', error);
      throw error; // Rethrow for handling in the component if necessary
    }
  }

}
