import { Injectable, signal } from '@angular/core';
import { UserService } from './user.service'; // Assume this handles Firebase auth logic
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  // Reactive signals to hold user flow state
  private email = signal<string | null>(null); // Email entered by the user
  private isSignUp = signal<boolean>(false); // Whether the flow is for signing up or logging in

  // Expose signals as readonly for components
  readonly email$ = this.email.asReadonly();
  readonly isSignUp$ = this.isSignUp.asReadonly();

  constructor(private readonly userService: UserService, private readonly router: Router) { }

  // Set email for the current flow
  setEmail(email: string): void {
    this.email.set(email);
  }

  // Get the email value directly
  getEmail(): string | null {
    return this.email();
  }

  // Set whether the user is signing up
  setSignUp(isSignUp: boolean): void {
    this.isSignUp.set(isSignUp);
  }

  // Handle Google sign-in (delegates to UserService)
  async continueWithGoogle(): Promise<void> {
    try {
      await this.userService.linkOrSignInWithGoogle();
      this.router.navigate(['/']); // Redirect to the homepage on success
    } catch (error) {
      console.error('Error during Google sign-in:', error);
      throw error; // Rethrow the error for the component to handle
    }
  }

  // Proceed to the password step
  proceedToPassword(): void {
    if (this.isSignUp()) {
      this.router.navigate(['/signup/password']);
    } else {
      this.router.navigate(['/login/password']);
    }
  }

  // Navigate back to the email input step
  editEmail(): void {
    if (this.isSignUp()) {
      this.router.navigate(['/signup']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  // Handle login with email/password (delegates to UserService)
  async loginWithPassword(password: string): Promise<void> {
    const email = this.getEmail();
    if (!email) throw new Error('Email is not set. Cannot log in.');

    try {
      await this.userService.loginWithEmailAndPassword(email, password);
      this.email.set(null);
      this.router.navigate(['/']); // Redirect to homepage on success
    } catch (error) {
      console.error('Login failed:', error);
      throw error; // Pass error to the component for user feedback
    }
  }

  // Handle sign-up with email/password
  async signUpWithPassword(password: string): Promise<void> {
    const email = this.getEmail();
    if (!email) throw new Error('Email is not set. Cannot sign up.');

    try {
      const user = this.userService.getCurrentUser();
      if (user?.isAnonymous) {
        // Convert anonymous account to email/password
        await this.userService.linkWithEmailAndPassword(email, password);
        this.email.set(null);
        this.isSignUp.set(false);
      } else {
        throw new Error(
          'Unexpected state: User is not anonymous. Cannot proceed with sign-up.'
        );
      }
      this.router.navigate(['/']); // Redirect to homepage on success
    } catch (error) {
      console.error('Sign-up failed:', error);
      throw error; // Pass error to the component for user feedback
    }
  }

  // Clear state between flows (e.g., on logout or flow reset)
  clearState(): void {
    this.email.set(null);
    this.isSignUp.set(false);
  }
}
