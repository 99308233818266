<div class="container py-4">
    <h1 class="text-center mb-4">Terms of Service</h1>
    <p class="text-center text-muted mb-4">Last Updated: 18 November 2024</p>
    <div class="row">
        <div class="col-12 col-md-10 offset-md-1">
            <h2 class="h4 mt-4">Acceptance of Terms</h2>
            <p>By accessing or using Find the Diamonds, you agree to these Terms of Service. If you do not agree, do not
                use the game.</p>

            <h2 class="h4 mt-4">User Accounts</h2>
            <ul>
                <li><strong>Registration:</strong> You may need to create an account to access certain features.</li>
                <li><strong>Responsibility:</strong> You are responsible for maintaining the confidentiality of your
                    account credentials.</li>
                <li><strong>Prohibited Conduct:</strong> You agree not to engage in any illegal activities, harassment,
                    cheating, or other prohibited behavior.</li>
            </ul>

            <h2 class="h4 mt-4">License to Use the Game</h2>
            <p>We grant you a limited, non-exclusive, non-transferable license to use the game. This license may be
                revoked if you violate these Terms.</p>

            <h2 class="h4 mt-4">Prohibited Activities</h2>
            <ul>
                <li><strong>Reverse Engineering and Unauthorized Access:</strong> You may not decompile, reverse
                    engineer, disassemble, or otherwise attempt to derive the game’s source code, algorithms, or
                    underlying structure.</li>
                <li><strong>Automated Play:</strong> Use of bots, scripts, or automated solvers to interact with the
                    game is strictly prohibited.</li>
                <li><strong>Data Scraping:</strong> You may not scrape, collect, or harvest data from the game without
                    our explicit written consent.</li>
            </ul>

            <h2 class="h4 mt-4">Virtual Goods and In-App Purchases</h2>
            <ul>
                <li><strong>No Ownership:</strong> Virtual items and in-game currency have no monetary value outside of
                    the game.</li>
                <li><strong>No Refunds:</strong> All purchases of virtual items or currency are final, and we do not
                    offer refunds.</li>
            </ul>

            <h2 class="h4 mt-4">User Content</h2>
            <ul>
                <li><strong>Ownership:</strong> You retain ownership of any content you create in the game but grant us
                    a license to use it for promotional or operational purposes.</li>
                <li><strong>Prohibited Content:</strong> You agree not to post content that is illegal, harmful, or
                    infringes the rights of others.</li>
            </ul>

            <h2 class="h4 mt-4">Termination</h2>
            <p>We reserve the right to suspend or terminate your account if you violate these Terms.</p>

            <h2 class="h4 mt-4">Disclaimer of Warranties</h2>
            <p>The game is provided "as is" without any warranties. We do not guarantee that the game will be error-free
                or uninterrupted.</p>

            <h2 class="h4 mt-4">Limitation of Liability</h2>
            <p>We are not liable for any indirect, incidental, or consequential damages arising from your use of the
                game.</p>

            <h2 class="h4 mt-4">Changes to These Terms</h2>
            <p>We may modify these Terms from time to time. If we do, we will provide notice within the game. Your
                continued use of the game constitutes acceptance of the new Terms.</p>

            <h2 class="h4 mt-4">Governing Law</h2>
            <p>These Terms are governed by the laws of the state of Delaware, United States of America.</p>

            <h2 class="h4 mt-4">Contact Us</h2>
            <p>If you have questions about these Terms, contact us at <a
                    href="mailto:support@findthediamonds.com">support&#64;findthediamonds.com</a>.</p>
        </div>
    </div>
</div>