<!-- Loading Spinner -->
<div *ngIf="boardRetrievingService.isLoadingBoard$()" class="spinner-container text-center">
    <div class="spinner"></div>
    <p>{{ boardRetrievingService.loadingMessage$() }}</p>
</div>

<!-- Formatting Spinner -->
<div *ngIf="!boardRetrievingService.isLoadingBoard$() && boardStateService.isFormattingBoard$()"
    class="spinner-container text-center">
    <div class="spinner"></div> Formatting board...
</div>

<!-- Game Board -->
<div *ngIf="!boardRetrievingService.isLoadingBoard$() && (boardStateService.isGameStarted$() || boardRetrievingService.alreadyPlayed$())"
    class="board">
    <div *ngFor="let row of boardStateService.boardStyle$(); let rowIndex = index" class="row">
        <div *ngFor="let cellStyle of row; let colIndex = index" class="cell"
            [ngClass]="'cell-' + boardStateService.boardSize$()" [ngStyle]="{
                    'background-color': cellStyle.backgroundColor,
                    'border-top': cellStyle.borderTop,
                    'border-right': cellStyle.borderRight,
                    'border-bottom': cellStyle.borderBottom,
                    'border-left': cellStyle.borderLeft
                }" (click)="handleCellClick(rowIndex, colIndex)">
            <span>{{ boardStateService.boardState$()[rowIndex][colIndex] === 'eliminated' ? '✖' :
                (boardStateService.boardState$()[rowIndex][colIndex] === 'diamond' ? '💎' : '') }}</span>
            <span *ngIf="boardStateService.incorrectDiamonds$()[rowIndex][colIndex]" class="overlay">❌</span>
            <span *ngIf="boardStateService.errorState$()[rowIndex][colIndex]" class="overlay">❌</span>
        </div>
    </div>
</div>