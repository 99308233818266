import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FirebaseError } from 'firebase/app';

import { LoginService } from '../../../services/login.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-signup-password',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './signup-password.component.html',
  styleUrl: './signup-password.component.scss'
})
export class SignupPasswordComponent implements OnInit {
  @ViewChild('passwordInput') passwordInput!: ElementRef;

  passwordForm: FormGroup;
  email: string | null = null;
  errorMessage: string = '';
  showPassword: boolean = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly loginService: LoginService,
    private readonly userService: UserService,
    private readonly router: Router
  ) {
    this.passwordForm = this.fb.group({
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8), // Minimum 8 characters
          Validators.pattern(/\d/), // At least one numeric character
          Validators.pattern(/\W/), // At least one non-alphanumeric character
          Validators.pattern(/[A-Z]/), // At least one uppercase character
        ]
      ],
      displayName: ['', [Validators.maxLength(20)]], // Display name is required
    });
  }

  ngOnInit(): void {
    this.email = this.loginService.getEmail(); // Retrieve email from the service
    if (!this.email) {
      this.router.navigate(['/signup']); // Redirect back if email is missing
    }
  }

  ngAfterViewInit(): void {
    // Focus the password input field
    this.passwordInput.nativeElement.focus();
  }

  async onContinue(): Promise<void> {
    if (this.passwordForm.valid) {
      try {
        let { password, displayName } = this.passwordForm.value;
        //check if displayName is empty and set it to Guest if empty
        if (displayName === '') {
          displayName = 'Guest';
        }
        await this.loginService.signUpWithPassword(password);
        await this.userService.updateDisplayName(displayName);
        this.router.navigate(['/']); // Redirect on success
      } catch (error) {
        if (error instanceof FirebaseError) {
          switch (error.code) {
            case 'auth/email-already-in-use':
              this.errorMessage = 'This email is already registered. Please log in instead.';
              break;
            case 'auth/password-does-not-meet-requirements':
              this.errorMessage = 'Your password does not meet the requirements.';
              break;
            case 'auth/invalid-email':
              this.errorMessage = 'The email address is not valid. Please enter a valid email.';
              break;
            case 'auth/weak-password':
              this.errorMessage = 'Your password is too weak. Please use at least 6 characters.';
              break;
            case 'auth/network-request-failed':
              this.errorMessage = 'Network error. Please check your connection.';
              break;
            case 'auth/internal-error':
              this.errorMessage = 'An internal error occurred. Please try again later.';
              break;
            default:
              this.errorMessage = 'An unexpected error occurred. Please try again.';
          }
        } else {
          console.error('Unexpected error:', error);
          this.errorMessage = 'An unknown error occurred. Please try again.';
        }
      }
    } else {
      this.errorMessage = 'Please enter a valid password.';
    }
  }

  editEmail(): void {
    this.loginService.editEmail(); // Navigate back to edit email
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword; // Toggle password visibility
  }

  // Password validation helpers
  isPasswordLengthValid(): boolean {
    return this.passwordForm.get('password')?.value?.length >= 8;
  }

  hasPasswordNumeric(): boolean {
    return /\d/.test(this.passwordForm.get('password')?.value || '');
  }

  hasPasswordNonAlphaNumeric(): boolean {
    return /\W/.test(this.passwordForm.get('password')?.value || '');
  }

  hasPasswordUppercase(): boolean {
    return /[A-Z]/.test(this.passwordForm.get('password')?.value || '');
  }

  async continueWithGoogle(): Promise<void> {
    try {
      await this.loginService.continueWithGoogle();
    } catch (error) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case 'auth/popup-closed-by-user':
            this.errorMessage = 'Sign-in popup was closed before completing. Please try again.';
            break;
          case 'auth/account-exists-with-different-credential':
            this.errorMessage =
              'This email is already associated with a different login method. Try logging in with that method.';
            break;
          case 'auth/network-request-failed':
            this.errorMessage = 'Network error. Please check your connection.';
            break;
          case 'auth/too-many-requests':
            this.errorMessage = 'Too many failed attempts. Please try again later.';
            break;
          case 'auth/internal-error':
            this.errorMessage = 'An internal error occurred. Please try again later.';
            break;
          default:
            this.errorMessage = 'An unexpected error occurred. Please try again.';
        }
      } else {
        console.error('Unexpected error:', error);
        this.errorMessage = 'An unknown error occurred. Please try again.';
      }
    }
  }
}
