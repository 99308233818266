import { Injectable, signal } from '@angular/core';

import { HintRule } from '../_rules/hint-rule.interface';
import { SingleCellColorRule } from '../_rules/single-cell-color-rule';
import { SingleRowOrColRule } from '../_rules/single-row-or-col-rule';
import { EliminateOutsideRowOrColForSingleColorRule } from '../_rules/outside-row-col-color-elimination-rule';
import { TouchingCellsRule } from '../_rules/touching-cells-rule';
import { RowOrColViolationRule } from '../_rules/row-or-col-violation-rule';
import { ThreeColorsInThreeRowsOrColsRule } from '../_rules/three-colors-in-three-rows-or-cols-rule';
import { TwoColorsInTwoRowsOrColsRule } from '../_rules/two-colors-in-two-rows-or-cols-rule';
import { FourColorsInFourRowsOrColsRule } from '../_rules/four-colors-in-four-rows-or-cols-rule';

@Injectable({
  providedIn: 'root'
})
export class HintService {
  private hintVisible = signal<boolean>(false);
  private hintText = signal<string>('');
  private hintStep = signal<number>(0);
  private rules: HintRule[] = [];
  private hintCooldownActive = signal<boolean>(false);
  private hintCooldownTimeLeft = signal<number>(0); // Time left in seconds
  private hintCooldownProgress = signal<number>(0); // Progress percentage (0-100)

  readonly hintVisible$ = this.hintVisible.asReadonly();
  readonly hintText$ = this.hintText.asReadonly();
  readonly hintStep$ = this.hintStep.asReadonly();
  readonly hintCooldownActive$ = this.hintCooldownActive.asReadonly();
  readonly hintCooldownTimeLeft$ = this.hintCooldownTimeLeft.asReadonly();
  readonly hintCooldownProgress$ = this.hintCooldownProgress.asReadonly();

  constructor() {
    this.rules = [
      new SingleCellColorRule(),
      new SingleRowOrColRule(),
      new EliminateOutsideRowOrColForSingleColorRule(),
      new TouchingCellsRule(),
      new TwoColorsInTwoRowsOrColsRule(),
      new ThreeColorsInThreeRowsOrColsRule(),
      new FourColorsInFourRowsOrColsRule(),
      new RowOrColViolationRule(),
    ];
  }

  provideHint(boardState: string[][], board: number[][]): void {
    if (this.hintCooldownActive()) {
      return; // Prevent providing a hint if the cooldown is active
    }

    for (const rule of this.rules) {
      const hint = rule.getHint(boardState, board, this.hintStep());
      if (hint) {
        // Update hint text and visibility
        this.hintText.set(hint);
        this.hintVisible.set(true);

        // Increment the hint step
        this.hintStep.update((prev) => prev + 1);

        // Start cooldown
        this.startCooldown();
        return;
      }
    }

    // If no valid hints are found
    this.hintText.set('No hints available at the moment!');
    this.hintVisible.set(true);
    this.startCooldown();
  }

  // Clears the hint when a cell is clicked
  clearHint(): void {
    this.hintText.set('');
    this.hintVisible.set(false);
    this.hintStep.set(0);
  }

  private startCooldown(): void {
    const cooldownDuration = 5000; // Cooldown duration in milliseconds
    const intervalStep = 100; // Update interval in milliseconds
    let elapsedTime = 0;

    this.hintCooldownActive.set(true);
    this.hintCooldownTimeLeft.set(cooldownDuration / 1000); // Set initial time left in seconds
    this.hintCooldownProgress.set(100); // Start at 100%

    const interval = setInterval(() => {
      elapsedTime += intervalStep;
      const timeLeft = Math.max(cooldownDuration - elapsedTime, 0);
      const progress = (timeLeft / cooldownDuration) * 100;

      this.hintCooldownTimeLeft.set(Math.ceil(timeLeft / 1000)); // Update time left in seconds
      this.hintCooldownProgress.set(progress); // Update progress percentage

      if (timeLeft <= 0) {
        clearInterval(interval);
        this.hintCooldownActive.set(false);
        this.hintCooldownTimeLeft.set(0);
        this.hintCooldownProgress.set(0); // Reset progress to 0
      }
    }, intervalStep); // Update every 50ms
  }

}
