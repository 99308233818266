import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { map, retry } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ApiBoardResponse, Board } from '../_models/board.model';
import { BoardStateService } from './board-state.service';
import { BoardSavingService } from './board-saving.service';
import { LocalStorageService } from './local-storage.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class BoardRetrievingService {
  private readonly baseApiUrl = environment.baseApiUrl;

  private isLoadingBoard = signal<boolean>(false);
  private loadingMessage = signal<string>('Loading board...');
  private alreadyPlayed = signal<boolean>(false);
  private isTutorialActive = signal<boolean>(false);
  private isPlayingDailyBoard = signal<boolean>(false);
  private displayHeader = signal<boolean>(false);

  readonly isLoadingBoard$ = this.isLoadingBoard.asReadonly();
  readonly loadingMessage$ = this.loadingMessage.asReadonly();
  readonly alreadyPlayed$ = this.alreadyPlayed.asReadonly();
  readonly isTutorialActive$ = this.isTutorialActive.asReadonly();
  readonly isPlayingDailyBoard$ = this.isPlayingDailyBoard.asReadonly();
  readonly displayHeader$ = this.displayHeader.asReadonly();

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly boardStateService: BoardStateService,
    private readonly boardSavingService: BoardSavingService,
    private readonly localStorageService: LocalStorageService,
    private readonly userService: UserService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const showHeaderRoutes = ['/login', '/login/password', '/signup', '/signup/password', '/profile', '/privacy', '/terms'];
        const isGameRoute = this.isTutorialActive() || this.isPlayingDailyBoard();
        const shouldShowHeader = showHeaderRoutes.includes(event.url) || isGameRoute;
        this.displayHeader.set(shouldShowHeader);
      }
    });
  }

  startLoadingMessages(): void {
    let step = 0;

    const messages = [
      'Loading board...',
      'Hiding diamonds...',
      'Adding colors...',
      'Getting ready to mine...'
    ];
    const intervalId = setInterval(() => {
      if (step >= messages.length) {
        clearInterval(intervalId);
      } else {
        this.loadingMessage.set(messages[step]);
        step++;
      }
    }, 1000); // Change messages every second
  }

  // Method to start the game
  startGame(shareId?: string): void {
    this.isTutorialActive.set(false);
    this.isPlayingDailyBoard.set(false);
    if (shareId) {
      this.fetchBoardDataByShareId(shareId);
    } else {
      this.fetchBoardData();
    }
  }

  startTutorial(): void {
    this.isTutorialActive.set(true);
    this.isPlayingDailyBoard.set(false);
    this.fetchBoardData();
  }

  startDailyBoard(): void {
    this.isTutorialActive.set(false);
    this.isPlayingDailyBoard.set(true);
    this.fetchBoardData();
  }

  private constructUrl(): string {
    if (this.isTutorialActive()) {
      return `${this.baseApiUrl}/boards?boardType=3`;
    }
    if (this.isPlayingDailyBoard()) {
      return `${this.baseApiUrl}/daily`;
    }
    return `${this.baseApiUrl}/boards`;
  }

  fetchBoardData(): void {
    this.isLoadingBoard.set(true);
    this.startLoadingMessages();
    this.alreadyPlayed.set(false);

    this.boardStateService.resetBoardState();

    const url = this.constructUrl();

    this.http.get<ApiBoardResponse>(url).pipe(
      map(response => this.transformBoardData(response)),
      retry({ count: 2, delay: 1000 }) // Retry 2 times with a 1-second delay between attempts
    ).subscribe({
      next: (boardResponse) => {
        // Check if the board has already been played by the user
        const userPlayed = !!boardResponse.userResult;
        this.alreadyPlayed.set(userPlayed);

        // Handle the daily board case
        if (this.isPlayingDailyBoard()) {
          const localDailyData = this.localStorageService.getDailyChallengeData(this.userService.userId$());

          // Check if local storage already has the board marked as completed
          if (localDailyData && localDailyData.completed && localDailyData.boardId === boardResponse.boardId) {
            console.log('Daily board already played, skipping local storage update.');
          } else {
            // Update local storage with daily challenge data
            this.localStorageService.setDailyChallengeData(
              this.userService.userId$(),
              boardResponse.boardId,
              boardResponse.dailyDate
            );

            // If the board has been completed, include additional stats
            if (userPlayed) {
              const updatedDailyData = {
                ...localDailyData,
                boardId: boardResponse.boardId,
                lastDailyDate: boardResponse.dailyDate,
                completed: true,
                stats: boardResponse.boardStats,
                completionTime: boardResponse.userResult?.completion_time,
                clicks: boardResponse.userResult?.clicks,
                boardClears: boardResponse.userResult?.clears,
              };

              this.localStorageService.updateLocalStorage(this.userService.userId$(), {
                dailyChallenge: updatedDailyData,
              });

              // Update UserService signal
              this.userService.setDailyChallengeCompleted(true);
            }
          }
        }

        if (userPlayed) {
          // Setup board state for a completed board
          this.boardStateService.setupCompletedBoard(
            boardResponse.board,
            boardResponse.boardId,
            boardResponse.shareId,
            boardResponse.boardSize,
            boardResponse.complexityRating,
            boardResponse.createdAt,
            boardResponse.userResult!.completion_time
          );

          this.applyPreviousUserActions(boardResponse.userResult!.clicks, boardResponse.userResult!.game_type_id);
          this.boardSavingService.loadStats(boardResponse.boardStats);
          this.boardSavingService.setRating(boardResponse.userResult?.rating ?? 0);
          this.boardStateService.setGameCompleted(); // Mark as completed
        } else {
          // Start the game normally for new boards
          this.boardStateService.startGame(
            boardResponse.board,
            boardResponse.boardId,
            boardResponse.shareId,
            boardResponse.boardSize,
            boardResponse.complexityRating,
            boardResponse.createdAt
          );
        }

        // Reset loading state
        this.isLoadingBoard.set(false);
        this.loadingMessage.set('Loading board...');
      },
      error: (error) => {
        console.error('Error fetching board data:', error);
        this.isLoadingBoard.set(false);
        this.loadingMessage.set('Loading board...');
      }
    });
  }

  fetchBoardDataByShareId(shareId: string): void {
    this.isLoadingBoard.set(true);
    this.startLoadingMessages();
    this.alreadyPlayed.set(false);

    this.boardStateService.resetBoardState();

    const url = `${this.baseApiUrl}/boards/${shareId}`;

    this.http.get<ApiBoardResponse>(url).pipe(
      map(response => this.transformBoardData(response)),
      retry({ count: 2, delay: 1000 })
    ).subscribe({
      next: (boardResponse) => {
        this.router.navigate(['/']);

        // Check if the board has already been played by checking `userResult`
        this.alreadyPlayed.set(!!boardResponse.userResult);

        if (this.alreadyPlayed()) {
          // For completed boards, set up without starting the game
          this.boardStateService.setupCompletedBoard(
            boardResponse.board,
            boardResponse.boardId,
            boardResponse.shareId,
            boardResponse.boardSize,
            boardResponse.complexityRating,
            boardResponse.createdAt,
            boardResponse.userResult!.completion_time
          );

          this.applyPreviousUserActions(boardResponse.userResult!.clicks, boardResponse.userResult!.game_type_id);
          this.boardSavingService.loadStats(boardResponse.boardStats);
          this.boardSavingService.setRating(boardResponse.userResult?.rating ?? 0);
          this.boardStateService.setGameCompleted(); // Mark as completed
        } else {
          // For new boards, start the game normally
          this.boardStateService.startGame(
            boardResponse.board,
            boardResponse.boardId,
            boardResponse.shareId,
            boardResponse.boardSize,
            boardResponse.complexityRating,
            boardResponse.createdAt
          );
        }

        // Set loading state to false
        this.isLoadingBoard.set(false);
        this.loadingMessage.set('Loading board...');
      },
      error: (error) => {
        console.error('Error fetching board data:', error);
        this.isLoadingBoard.set(false);
        this.loadingMessage.set('Loading board...');
        this.router.navigate(['/']);
      }
    });
  }

  // Transform API response into a more usable format
  private transformBoardData(response: ApiBoardResponse): Board {
    const { boardData, userResult, boardStats } = response;

    return {
      boardId: boardData.board_id,
      board: boardData.data,  // Parse board data if it's a stringified array
      boardSize: parseInt(boardData.size, 10),
      complexityRating: parseFloat(boardData.elo_rating),
      createdAt: new Date(boardData.created_at),
      dailyDate: new Date(boardData.daily_date),
      shareId: boardData.share_id,
      userResult: userResult ? {
        board_id: userResult.board_id,
        game_type_id: userResult.game_type_id,
        completion_time: userResult.completion_time,
        clicks: userResult.clicks.map(click => ({
          action: click.action,
          cell: click.cell,
          solved_at: new Date(click.solved_at)
        })),
        clears: userResult.clears,
        solve_date: new Date(userResult.solve_date),
        rating: userResult.rating ? userResult.rating : undefined,
        user_id: userResult.user_id
      } : null,
      boardStats: boardStats ? {
        averageBoardClears: boardStats.averageBoardClears,
        averageClicks: boardStats.averageClicks,
        averageCompletionTime: boardStats.averageCompletionTime,
        fastestCompletionTime: boardStats.fastestCompletionTime,
        medianClicks: boardStats.medianClicks,
        medianCompletionTime: boardStats.medianCompletionTime,
        totalCompletions: boardStats.totalCompletions,
        userPercentile: boardStats.userPercentile,
      } : null
    };
  }

  applyPreviousUserActions(clicks: { action: string; cell: string; solved_at: Date }[], gameTypeId: number): void {
    // Find the index of the last "clear" action
    const lastClearIndex = clicks
      .map((click) => click.action)
      .lastIndexOf("clear");

    // Get only the actions after the last "clear" (if any)
    const filteredClicks = clicks.slice(lastClearIndex + 1);

    // Apply each action
    filteredClicks.forEach((click) => {
      if (!click?.cell) {
        console.warn("Skipping invalid click entry:", click);
        return;
      }

      const [row, col] = click.cell.split("-").map(Number);
      if (isNaN(row) || isNaN(col)) {
        console.warn("Invalid cell coordinates:", click.cell);
        return;
      }

      switch (click.action) {
        case "eliminated":
          this.boardStateService.setCellState(row, col, "eliminated");
          break;
        case "diamond":
          this.boardStateService.setCellState(row, col, "diamond");
          break;
        case "reset":
          this.boardStateService.setCellState(row, col, "empty");
          break;
        default:
          console.warn("Unknown action:", click.action);
      }
    });

    // Save the filtered actions to `userActions`
    this.boardStateService.setPreviousUserActions(filteredClicks);

    // If gameTypeId is 1, eliminate all relevant cells
    if (gameTypeId === 1) {
      this.boardStateService.eliminateAllCells();
    }
  }

  resetRetrievingStates(): void {
    this.isLoadingBoard.set(false);
    this.loadingMessage.set('Loading board...');
    this.alreadyPlayed.set(false);
    this.isTutorialActive.set(false);
    this.isPlayingDailyBoard.set(false);
  }

  returnHome(): void {
    this.boardStateService.resetBoardState();
  }

}