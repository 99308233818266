import { HttpInterceptorFn, HttpRequest, HttpHandlerFn, HttpErrorResponse } from '@angular/common/http';
import { Auth } from '@angular/fire/auth';
import { getAuth } from 'firebase/auth';
import { switchMap, catchError, from, BehaviorSubject, throwError } from 'rxjs';
import { filter, retry, take } from 'rxjs/operators';
import { inject } from '@angular/core';

import { UserService } from '../services/user.service';

const maxRetries = 2; // Set your max retry attempts
const isRefreshing = new BehaviorSubject(false);
const refreshTokenSubject = new BehaviorSubject<string | null>(null);

// Interceptor for Firebase JWT
export const jwtInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn) => {
    const auth = inject(Auth); // Use AngularFire's Auth service
    const userService = inject(UserService);

    // Skip certain requests by checking for a custom 'skip' header
    if (shouldSkipIntercept(req)) {
        return next(removeSkipHeader(req));
    }

    // preemtively refresh the token if it's about to expire
    const refreshToken$ = userService.needsTokenRefresh() ? from(auth.currentUser!.getIdToken(true)) : from(auth.currentUser!.getIdToken());

    // Get the current token from Firebase
    return refreshToken$.pipe(
        switchMap(token => {
            // Clone the request and add the Authorization header
            const authReq = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });

            // Proceed with the request
            return next(authReq);
        }),
        catchError((error: HttpErrorResponse) => {
            // If a 401 Unauthorized occurs, try refreshing the token
            if (error.status === 401) {
                return handleTokenRefresh(req, next, auth);
            } else {
                return throwError(() => error);
            }
        }),
        retry({ count: maxRetries, delay: 1000 }),
        catchError((error: HttpErrorResponse) => {
            console.error(`Request failed after ${maxRetries + 1} retries: ${error.message}`);
            return throwError(() => error);
        })
    );
};

// Function to skip interceptor for certain requests (based on a custom 'skip' header)
function shouldSkipIntercept(req: HttpRequest<any>): boolean {
    return req.headers.has('skip');
}

function removeSkipHeader(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({ headers: req.headers.delete('skip') });
}

// Handle token refresh logic - this is a catch all in addition to the pre-emptive refresh
function handleTokenRefresh(req: HttpRequest<unknown>, next: HttpHandlerFn, auth: ReturnType<typeof getAuth>) {
    const currentUser = auth.currentUser;

    if (!currentUser) {
        // If there is no authenticated user, handle the error (e.g., log out)
        console.error('No user is currently authenticated.');
        return throwError(() => new Error('No authenticated user.'));
    }

    if (!isRefreshing.value) {
        isRefreshing.next(true);
        refreshTokenSubject.next(null);

        return from(currentUser.getIdToken(true)).pipe(
            switchMap(newToken => {
                isRefreshing.next(false);
                refreshTokenSubject.next(newToken);
                // Retry the request with the new token
                const retryRequest = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${newToken}`
                    }
                });
                return next(retryRequest);
            }),
            catchError(error => {
                isRefreshing.next(false);
                console.error('Error refreshing token:', error);
                return throwError(() => error);
            })
        );
    } else {
        // If refreshing is already in progress, queue the request
        return refreshTokenSubject.pipe(
            filter(token => token != null),
            take(1),
            switchMap(token => {
                // Retry the request with the newly refreshed token
                const retryRequest = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return next(retryRequest);
            })
        );
    }
}